@import "./src/styles/colors";

.activity-group {
  fill: var(--color-report-activity);
}

.blocks-group {
  fill: var(--color-report-blocks);
}

.ss-group {
  fill: var(--color-report-malware);
}
