@import "../../../styles/mixins/typography";
@import "../../../styles/variables";
@import "~@sportal/cdk/styles/mixins/direction";

.device-card-mobile {
  padding: 12px 16px;
  margin-bottom: 8px; // TODO: move out to list styles
  position: relative;
  border-radius: 3px;

  &__content {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    @media (max-width: $screen-xs-max) {
      flex-flow: column nowrap;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__title {
    flex: 0 0 30%;
    @include font-weight("semi-bold");
  }

  &__device-name {
    flex: 1 1 auto;
    @include ellipsis(pre);
  }

  &__menu {
    flex: 0 0 0;
    @include side-spacing(margin, right, -4px);
    width: 20px;
    cursor: pointer;

    @media (max-width: $screen-xs-max) {
      position: absolute;
      @include fixed-position(right, 12px);
    }
  }

  &--dark {
    color: $white;
    background-color: var(--color-primary);
  }

  &--light {
    background-color: $black-haze;
  }
}
