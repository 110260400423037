@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";

.time-indicator {
  position: absolute;
  width: 1px;
  background-color: var(--color-primary);
  color: var(--color-primary);
  bottom: 0;
  z-index: 6;
  @include font-size("regular");
  height: 274px;

  @media (max-width: $screen-xs-max) {
    height: 264px;
  }

  &__info {
    position: relative;

    > span {
      position: absolute;
      @include fixed-position(left, 0);
      white-space: nowrap;
      padding: 0 5px;
    }
  }
}
