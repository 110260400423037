@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/typography";

.table {
  width: 100%;
  padding: 8px 0;

  .icon {
    color: $mid-gray;
    font-size: 14px;
  }

  .thead .th,
  .tbody .td {
    align-self: center;

    &:not(.td--actions):not(.td--deviceType) {
      @include ellipsis();
    }
  }
}
