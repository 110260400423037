@import "../../../../styles/colors";

@mixin item-divider {
  position: relative;

  &.clear-data {
    margin: 10px 0;

    &::before,
    &::after {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      background-color: $mystic;
      position: absolute;
      left: 0;
    }

    &::before {
      top: -5px;
    }

    &::after {
      top: calc(100% + 5px);
    }
  }
}
