@import "../../../../../styles/mixins/typography";

.remove-roaming-device-dialog {
  &__note {
    margin-top: 4px;
    margin-bottom: 24px;
    color: $mid-gray;
  }

  &__device-name {
    @include font-weight(semi-bold);
  }
}
