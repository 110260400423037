//Gray palette
$black: #000000;
$mine-shaft: #212121;
$mid-gray: #66676b;
$dusty-gray: #969696;
$gray-chateau: #a5aeb2;
$silver-sand: #c5cacc;
$mystic: #e0eaea;
$black-haze: #f4f4f4;
$white-ice: #f2fafd;
$white: #ffffff;

//Dark palette
$big-stone: #17313f;
$fiord: #455a65;
$bermuda-gray: #678fa1;

//Blue palette
$eastern-blue: #1785a9;
$picton-blue: #2ab5e2;
$spray: #88d8f2;

//Brand palette
$scarlet: #ff3101;
$outrageous-orange: #ff5a34;
$california: #f19f02;
$java: #1fbcaf;

$brand-primary: $picton-blue;
$brand-success: $java;
$brand-warning: $california;
$brand-danger: $scarlet;
$brand-danger-alt: $outrageous-orange;

//HSL
$brand-primary-hs: hue($brand-primary), saturation($brand-primary);
$brand-primary-lightness: lightness($brand-primary);
$brand-danger-alt-hs: hue($brand-danger-alt), saturation($brand-danger-alt);
$brand-danger-alt-lightness: lightness($brand-danger-alt);
$brand-success-hs: hue($brand-success), saturation($brand-success);
$brand-success-lightness: lightness($brand-success);
$brand-warning-hs: hue($brand-warning), saturation($brand-warning);
$brand-warning-lightness: lightness($brand-warning);
