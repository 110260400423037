@import "~@sportal/cdk/styles/mixins/direction";
@import "./../../styles/colors";

.horizontal-menu {
  display: flex;
  width: 100%;

  background-color: $black-haze;

  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  & &__content {
    display: flex;
    list-style: none;
    padding: 0 16px;
  }

  & &__item {
    height: 48px;
    @include side-spacing(margin, right, 8px);

    &:last-child {
      @include side-spacing(margin, right, 0);
    }
  }
}
