@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../../../../../styles/mixins/typography";

.protection-status {
  display: flex;
  align-items: center;
  min-width: 0;

  &--protected .icon,
  &--protected {
    color: var(--color-success);
  }

  &--not-protected .icon,
  &--not-protected {
    color: var(--color-danger);
  }

  &--not-protected {
    border-bottom: 1px dashed var(--color-danger);
    cursor: pointer;
  }

  &__value {
    display: flex;
    align-items: center;
    height: 20px;
    @include font-weight("semi-bold");
    @include font-size("small");
    @include side-spacing(margin, left, 4px);
    @include ellipsis();
  }
}
