@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/typography";

$toggle-button-height: 40px;
$label-height: 21px;

.dropdown {
  &__label {
    @include font-size("small");
    margin-bottom: 4px;
  }

  &-menu-container {
    z-index: $zindex-dropdown;
    padding: 4px 0;
  }

  &__menu {
    padding: 4px 0;
    background-color: $white;
    box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }

  &__item {
    padding: 6px 16px;
    color: $mine-shaft;

    a {
      color: $mine-shaft;
    }

    &:hover a {
      color: $black-haze;
    }

    &--highlighted {
      background-color: var(--color-primary);
      color: $black-haze;
      a {
        color: $black-haze;
      }
    }

    &--disabled {
      color: $gray-chateau;

      &:hover {
        color: $gray-chateau;
        a {
          color: $gray-chateau;
        }
      }
    }
  }
}
