@import "../../../../styles/colors";
@import "../../../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";

.detailed-service-panel--not-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .detailed-service-panel {
    &__header {
      flex-wrap: wrap;
      margin-bottom: 5px;
    }
  }
}
