@import "../../../../styles/colors";
@import "../../../../styles/mixins/grid";

.layout__header {
  display: flex;
  align-items: center;
  min-height: 70px;
  background-color: var(--color-accent-midtone);
  color: $white;

  & &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    @include adaptive($screen-xs-max, $screen-sm-max, $screen-md-max);

    @media (min-width: $screen-sm-min) {
      padding: 0 24px;
      margin: 0 auto;
    }
  }
}
