@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../styles/colors";
@import "../../../../styles/mixins/typography";
@import "../../../../styles/variables";

@mixin profile-card-image {
  mask-size: 64px;
  mask-repeat: no-repeat;
  mask-position: center;
}

.wizard-profiles {
  @media (max-width: $screen-xs-max) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__content {
    @media (max-width: $screen-xs-max) {
      padding: 16px 0;
    }

    @media (min-width: $screen-sm-min) {
      padding-bottom: 16px;
    }
  }

  .wizard-profiles-hint {
    @include hint-txt;

    @media (max-width: $screen-xs-max) {
      margin-top: 4px;
    }

    @media (min-width: $screen-sm-min) {
      margin-top: 8px;
    }
  }

  .add-profile-controls {
    @media (max-width: $screen-xs-max) {
      text-align: center;
      margin-top: 24px;
    }

    @media (min-width: $screen-sm-min) {
      margin-top: 40px;
    }

    &__title {
      @include font-size(small);
      margin-bottom: 8px;
    }

    &__button {
      @media (max-width: $screen-xs-max) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .icon {
        @include side-spacing(margin, right, 8px);
      }
    }
  }
}

.wizard-card {
  background-color: $black-haze;
  border: 1px solid $silver-sand;
  border-radius: 3px;
  padding: 16px;

  & + .wizard-card {
    margin-top: 16px;
  }

  &:last-child {
    margin-bottom: 32px;
  }

  .profile-image {
    height: 64px;
    flex: 0 0 64px;
    @include side-spacing(margin, right, 16px);

    &.default {
      background-color: $mid-gray;
      mask: url("../../../../styles/images/default-card.svg");
      @include profile-card-image;
    }

    &.kids {
      background-color: var(--color-accent-light);
      mask: url("../../../../styles/images/strict-age-group-card.svg");
      @include profile-card-image;
    }

    &.adults {
      background-color: var(--color-primary);
      mask: url("../../../../styles/images/light-age-group-card.svg");
      @include profile-card-image;
    }

    &.teens {
      background-color: var(--color-accent-highlight);
      mask: url("../../../../styles/images/medium-age-group-card.svg");
      @include profile-card-image;
    }
  }
}

.wizard-profile {
  display: flex;

  @media (max-width: $screen-xs-max) {
    &:not(:first-child) {
      min-height: 236px;
    }
  }

  @media (min-width: $screen-sm-min) {
    min-height: 108px;
  }

  &:not(.wizard-profile-add) {
    align-items: center;

    @media (max-width: $screen-xs-max) {
      align-items: flex-start;
    }
  }

  .profile-image {
    @media (max-width: $screen-xs-max) {
      &:not(.default) {
        display: none;
      }
    }
  }
}

.wizard-profile-add {
  justify-content: center;
  flex-direction: column;

  &__title {
    margin-bottom: 14px;
  }

  &__age-group {
    display: flex;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;

      .age-group-button + .age-group-button {
        margin-top: 8px;
      }

      .age-group-cancel {
        margin-top: 20px;
      }
    }

    @media (min-width: $screen-sm-min) {
      .age-group-button {
        @include side-spacing(margin, right, 16px);
        min-width: 120px;
      }

      .age-group-cancel {
        @include side-spacing(margin, left, auto);
      }
    }
  }
}

.wizard-profile-card {
  @media (min-width: $screen-sm-min) {
    padding: 12px 16px;
  }

  &__title-default {
    margin-bottom: 6px;
  }

  &__form,
  &__form-fields {
    @media (max-width: $screen-xs-max) {
      flex-direction: column;
    }
  }

  &__form {
    display: flex;
    flex: 1;
    @media (min-width: $screen-sm-min) {
      align-items: center;
    }

    &-fields {
      display: flex;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 24px;
      }

      @media (max-width: $screen-sm-max) {
        flex: 1;
      }

      @media (min-width: $screen-md-min) {
        flex-basis: 75%;
      }

      > div {
        flex: 1;
      }
    }

    .input {
      @media (min-width: $screen-sm-min) {
        @include side-spacing(margin, right, 16px);
      }
      margin-bottom: 0;
    }

    .select {
      @media (min-width: $screen-sm-min) {
        @include side-spacing(margin, right, 8px);
      }
    }
  }

  &__remove-profile {
    margin-top: 6px;
    color: var(--color-danger);
    @include side-spacing(margin, left, auto);
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      @include side-spacing(margin, right, 8px);
    }
  }
}
