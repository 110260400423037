@import "../../styles/colors";

.category-toggle {
  .button--custom {
    width: 92px;
    padding: 5px 8px;
    background-color: $black-haze;
    color: var(--color-main);

    &:hover {
      border-color: $dusty-gray;
    }

    .fa-ban {
      color: var(--color-danger);
    }

    .fa-check {
      color: var(--color-success);
    }
  }
  .rainbow-hamburger {
    span {
      height: 2px;
      width: 100%;

      &:not(:first-child) {
        background-color: var(--color-success);
      }

      &:first-child {
        background-color: var(--color-danger);
      }
    }

    span + span {
      margin-top: 3px;
    }
  }
}
