@import "../../../styles/colors";

.device-type-tooltip {
  .icon {
    width: 20px;
    color: $mid-gray;

    &.new-request-icon {
      color: var(--color-primary);
    }
  }
}
