@import "../../../../styles/variables";

.account-tab {
  &__fieldset {
    max-width: 445px;

    @media (max-width: $screen-xs-max) {
      max-width: 100%;
    }
  }

  &__row {
    margin-bottom: 24px;
  }
}
