@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/colors";
@import "../../styles/variables";

.categories-item {
  &-wrapper {
    position: relative;
  }

  &--open {
    border: 1px solid var(--color-primary);

    &-bottom {
      border-radius: 3px 3px 0 0;
    }

    &-top {
      border-radius: 0 0 3px 3px;
    }
  }

  &__list {
    width: 100%;
    background-color: $white;
    z-index: $zindex-dropdown;
    padding: 15px 20px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0 0 3px 3px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

    .categories-item__protection {
      @include side-spacing(margin, left, 4px);
    }
  }
}
