@import "../../styles/colors";

@mixin age-segmented-button($modifier, $color) {
  .segmented-button--#{$modifier} {
    color: $color;

    label:hover {
      color: $white;
      background-color: $color;
      border-color: $color;
    }
  }

  .segmented-button--#{$modifier}
    .segmented-button__radio-button:checked
    + label {
    color: $white;
    background-color: $color;
    border-color: $color;
  }
}

.segmented-age-groups {
  .segmented-buttons-group__block {
    @include age-segmented-button(kids, $bermuda-gray);
    @include age-segmented-button(teens, var(--color-accent-highlight));
    @include age-segmented-button(adults, var(--color-primary));
  }
}
