@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../../styles/mixins/typography";
@import "../../../../../styles/colors";
@import "../../../../../styles/variables";

.url-options {
  &__info {
    @include hint-txt;
  }

  &__info,
  &__title {
    margin-bottom: 8px;
  }

  &__actions {
    display: flex;
    margin-top: 16px;
    margin-bottom: 32px;

    .button {
      min-width: 120px;
      @media (max-width: $screen-xs-max) {
        width: 50%;
      }
    }

    .button + .button {
      @include side-spacing(margin, left, 16px);
    }
  }

  .url-option {
    align-items: start;
    margin-bottom: 8px;

    & input {
      left: -100000px;
      border: 0;
      opacity: 0;
    }

    .radio-switch {
      margin-top: 2px;
    }

    &:focus-within {
      outline: auto 1px Highlight;
      outline: -webkit-focus-ring-color auto 1px;
      outline-offset: 4px;
    }

    &__label {
      display: flex;
      flex-direction: column;

      &__url {
        color: var(--color-accent-midtone);
      }

      &__tip {
        @include hint-txt;
      }
    }
  }
}
