@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/mixins/typography";

.wizard-sidebar {
  color: $white;

  &__title {
    margin-bottom: 24px;
  }

  &__description {
    @include font-size("small");
  }
}
