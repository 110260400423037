@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/typography";
@import "../../../styles/mixins/device";

.profile-device-card {
  background-color: $black-haze;
  border-radius: 3px;
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &__content-container {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__main-info {
    display: flex;

    .accessible-cell {
      min-width: 20px;
      @include ellipsis();

      + .accessible-cell {
        @include side-spacing(margin, left, 8px);
      }
    }
  }

  .device-name .tooltip-referrer {
    padding: 4px 0;
  }

  &__title {
    margin-bottom: 4px;
    @include font-weight("semi-bold");
    line-height: 24px;
  }
}
