@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../styles/colors";

.table:not(.table--with-footer) {
  .tbody {
    .tr:last-child {
      border-bottom: 1px solid rgba($silver-sand, 0.5);
    }
  }
}

.tbody {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  &--scroll-active {
    &:not(.tbody--scroll-overlay) {
      .tr:not(.tr--details) {
        @include side-spacing(padding, right, 0);
      }

      .tr--details {
        @include side-spacing(padding, right, 32px);
      }
    }
  }

  .tr {
    padding: 12px 16px;

    &:not(:first-child) {
      border-top: 1px solid rgba($silver-sand, 0.5);
    }

    &:nth-child(even) {
      background-color: $black-haze;
    }

    &:hover {
      background-color: $white-ice;
    }

    .td {
      &--actions {
        text-align: center;
      }
    }
  }
}
