@import "../../../styles/colors";
@import "../../../styles/mixins/typography";

.wizard-step {
  position: relative;
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: default;

  &__title {
    @include font-size("tiny");
    line-height: 14px;
  }

  .round-tab {
    @include font-size("small");
    border: 2px solid;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    background-color: var(--color-accent-dark);
    border-radius: 50%;
    z-index: 2;
  }

  &--active {
    color: $white;

    .round-tab {
      border-color: $white;
    }
  }

  &--disabled {
    color: $gray-chateau;

    .round-tab {
      border-color: var(--color-accent-midtone);
      color: var(--color-accent-midtone);
    }
  }

  &--completed {
    color: $gray-chateau;

    .round-tab {
      border-color: $gray-chateau;
    }
  }
}
