@import "../../styles/variables";

.protection-picker {
  max-width: 290px;
  margin-bottom: 8px;

  @media (max-width: $screen-xs-max) {
    max-width: 100%;
  }
}
