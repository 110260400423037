@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/typography";

.infected-devices {
  background-color: var(--color-accent-dark);
  color: $white;

  &__footer {
    padding: 8px 16px;
    @include font-size(small);
    @include italic;
    text-align: center;
    color: $silver-sand;
    background-color: var(--color-accent-midtone);
  }

  &__container {
    @include adaptive($screen-xs-max, $screen-sm-max, $screen-md-max);
    margin: 0 auto;
    padding-top: 20px;

    &-description {
      @include font-size(regular);
      color: var(--color-accent-light);
      padding: 0 16px;
    }
  }

  .infection {
    padding: 0 8px;
    min-height: 200px;

    &__header {
      @include font-size(large);
      @include font-weight(semi-bold);
      overflow-wrap: break-word;

      .fa-bug {
        @include side-spacing(margin, right, 10px);
        color: var(--color-danger-alt);
      }
    }

    .infected-device {
      margin: 20px 0 0;

      &__label {
        @include font-size(regular);
      }

      &__name {
        @include font-size(medium);
        @include font-weight(semi-bold);
        white-space: pre-wrap;
        color: var(--color-danger-alt);
        overflow-wrap: break-word;
      }
    }

    &__description {
      @include font-size(regular);
      margin-top: 24px;

      a {
        color: var(--color-primary);
        text-decoration: none;

        &:focus,
        &:hover {
          color: var(--color-primary-hover);
        }
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    &__container {
      padding: 16px 124px;

      &-description {
        padding: 0 8px;
      }
    }

    &__footer {
      padding: 8px;
    }
  }

  .carousel {
    &-slider {
      padding: 24px 0;

      &__arrow {
        color: $white;
        background-color: var(--color-accent-midtone);
      }
    }

    &-indicators {
      &__item {
        background-color: var(--color-accent-midtone);

        &--active {
          background-color: $white;
        }
      }
    }
  }
}
