@import "../../styles/variables";
@import "~@sportal/cdk/styles/mixins/direction";

@mixin profile-card-columns($iteration) {
  grid-template-columns: repeat($iteration, minmax(0, 1fr));
}

.profile-cards {
  display: grid;
  @include profile-card-columns(4);
  grid-gap: 30px;
  margin-top: 24px;

  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @include profile-card-columns(3);
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @include profile-card-columns(2);
  }

  @media (max-width: $screen-xs-max) {
    display: block;
    margin-bottom: 24px;

    .profile-card {
      margin: 0 auto;
      max-width: 470px;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}
