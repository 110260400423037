@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../styles/mixins/typography";
@import "../../../../styles/colors";

.block-page-tab {
  .bypass-pin-options-title {
    @include font-size("large");
    @include font-weight("semi-bold");
    margin-bottom: 16px;
  }

  &__checkbox {
    @include font-size("small");
    margin-bottom: 12px;
    &:last-of-type {
      margin-bottom: 0;
    }

    &-input {
      @include font-size("regular");
      margin-bottom: 6px;
    }
  }

  &__pin {
    margin-bottom: 24px;

    &-required {
      margin-bottom: 4px;
      @include font-size("small");
    }

    &-hint {
      display: inline-flex;
      align-items: center;
      @include side-spacing(margin, left, 16px);

      .icon {
        color: var(--color-accent-midtone);
        @include side-spacing(margin, right, 8px);
      }
    }

    &-error {
      color: var(--color-danger);
      margin-top: 5px;
    }
  }
}
