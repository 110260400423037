@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../styles/colors";
@import "../../../../styles/mixins/typography";
@import "../../../../styles/variables";

.sort-button {
  display: flex;
  align-items: center;
  width: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 12px 8px;
  color: inherit;

  @media (max-width: $screen-xs-max) {
    padding: 8px 0;
    margin: 8px 16px -8px;
    width: calc(100% - 32px);

    &:disabled {
      display: none;
    }
  }

  &__content {
    @include font-weight("semi-bold");
    @include ellipsis();
  }

  &__icon.icon {
    flex-shrink: 0;
    @include side-spacing(margin, left, 4px);

    &.sort-button__icon--unsorted {
      color: $silver-sand;
    }
  }
}
