@import "../../styles/variables";
@import "../../styles/colors";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  z-index: $zindex-loader;
  background-color: rgba(81, 82, 85, 0.5);

  $loader__dot-color: white;
  $loader__dot-size: 25px;
  $loader__dot-animation-scale: 0.25;
  $loader__dot-distance: $loader__dot-size / 2;

  &__dot {
    height: $loader__dot-size;
    width: $loader__dot-size;

    border-radius: $loader__dot-size / 2;
    margin-right: $loader__dot-distance;
    background-color: $white;

    animation-name: loader__dot;
    animation-duration: 0.8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  &__dot--2 {
    animation-delay: -0.4s;
  }

  @keyframes loader__dot {
    50% {
      opacity: 0.5;
      transform: scale(0.6);
    }
  }
}
