@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/mixins/typography";
@import "../../styles/mixins/grid";

.infected-devices__accordion {
  .accordion {
    padding: 10px 0 0;

    &-item {
      padding: 8px 16px 16px;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(81, 82, 85, 0.2);
      }

      &--open {
        background-color: rgba(0, 0, 0, 0.2);

        .fa-caret-down {
          transform: rotate(180deg);
        }
      }

      &__content {
        margin-top: 10px;
        overflow: hidden;

        a {
          color: var(--color-primary);
          text-decoration: none;

          &:focus,
          &:hover {
            color: var(--color-primary-pressed);
          }
        }
      }
    }
  }

  .infected-device {
    &__header {
      @include font-size(large);
      @include font-weight(semi-bold);

      .fa-bug {
        @include side-spacing(margin, right, 10px);
        color: var(--color-danger-alt);
      }

      .fa-caret-down {
        @include side-spacing(margin, left, 10px);
      }
    }

    &__label {
      @include font-size(regular);
      margin-top: 15px;
    }

    &__name {
      @include font-size(medium);
      color: var(--color-danger-alt);
      overflow-wrap: break-word;
    }

    &__description a {
      color: var(--color-primary);
      text-decoration: none;
    }
  }
}
