@import "./../../../styles/colors";

.wizard-mobile-title {
  padding: 16px;
  background-color: $black-haze;

  .single-wizard-page & .tab-title {
    margin-bottom: 8px;
  }
}
