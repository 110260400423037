@import "../../../styles/colors";

.table-mobile {
  display: block;
  width: 100%;
  margin: 8px 0;

  .icon {
    font-size: 14px;
    color: $mid-gray;
  }
}
