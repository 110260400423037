@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";

.schedule {
  width: 100%;
  position: relative;
  @include font-size("small");

  @media (min-width: $screen-lg-min) {
    @include side-spacing(padding, right, 16px);
  }

  &__table {
    display: flex;
    padding-top: 35px;

    @media (min-width: $screen-lg-min) {
      @include side-spacing(padding, right, 32px);
    }
  }

  &__grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 745px;
    position: relative;
  }

  .period-indicator {
    position: absolute;
    top: -18px;

    &--am {
      @include fixed-position(left, 0);
    }

    &--pm {
      @include fixed-position(left, 50%);
    }
  }

  &__data,
  &__hours {
    display: flex;
  }

  &__grid__body,
  &__hours {
    @include side-spacing(margin, left, 1px);
  }

  &__hours {
    margin-bottom: 3px;
  }

  &__grid__body {
    position: relative;
  }

  &__cell {
    flex: 1;
    margin-top: -1px;

    &:not(.schedule__cell__day) {
      @include side-spacing(margin, left, -1px);
    }

    &__day,
    &__data {
      height: 32px;
    }

    &__day {
      display: flex;
      align-items: center;
      @include side-spacing(padding, right, 16px);
    }

    &__data {
      position: relative;
      background-color: $white;
      border: 1px solid $silver-sand;
    }
  }

  .schedule__empty-cell {
    height: 21px;
  }

  .scheduled {
    position: absolute;
    top: 0;
    @include fixed-position(left, 0);
    height: calc(100% + 1px);
    width: calc(100% + 1px);

    &--homework {
      z-index: 4;
      background-color: rgba(var(--color-schedule-timeline-1-rgb), 0.4);
    }

    &--internet-off {
      z-index: 5;
      background-color: rgba(var(--color-schedule-timeline-2-rgb), 0.4);
    }
  }

  &__legend {
    display: flex;
    margin-top: 10px;
    @include side-spacing(margin, left, auto);

    .legend__item {
      display: flex;
      align-items: center;

      &:before {
        content: "";
        width: 12px;
        height: 12px;
        @include side-spacing(margin, right, 12px);
      }

      & + .legend__item {
        @include side-spacing(margin, left, 30px);
      }

      &--homework:before {
        background-color: rgba(var(--color-schedule-timeline-1-rgb), 0.4);
      }

      &--internet-off:before {
        background-color: rgba(var(--color-schedule-timeline-2-rgb), 0.4);
      }
    }
  }
}

.schedule__mobile {
  @include font-size("small");
  display: flex;
  flex-direction: column;
  margin-left: -16px;
  margin-right: -12px;

  .schedule {
    &__tab-interface,
    &__table {
      background-color: $black-haze;
    }

    &__tab-interface {
      display: flex;
      justify-content: center;
      padding-top: 14px;

      > div {
        min-width: 115px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      span:first-child,
      span:last-child {
        cursor: pointer;
      }

      .tab__name {
        margin: 0 16px;
      }
    }

    &__data .schedule__cell:last-child {
      @include side-spacing(border, right, 0);
    }

    &__cell__day {
      border: 1px solid $silver-sand;
      @include side-spacing(border, left, 0);
      @include side-spacing(padding, left, 16px);
    }

    &__days {
      display: flex;
      flex-direction: column;
      margin-top: -1px;
      @include side-spacing(margin, right, -1px);

      .day-time {
        margin-bottom: 3px;
        text-align: center;
        min-height: 18px;
      }
    }

    &__legend {
      flex-wrap: wrap;
      margin-top: 5px;
      padding: 0 16px;
      justify-content: flex-end;
    }
  }
}
