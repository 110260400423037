@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/colors";
@import "../../../styles/mixins/grid";
@import "../../../styles/mixins/typography";
@import "../../../styles/mixins/sidebar";
@import "../../../styles/variables";
@import "../../../styles/collapse";

.profile-section {
  &__header {
    cursor: default;

    &__toggle {
      cursor: pointer;
      color: var(--color-primary);
      -webkit-tap-highlight-color: transparent;

      .icon {
        margin: 0 8px;
      }
    }
  }

  &__title {
    background-color: $white;
    padding: 16px 0;
  }

  &__description {
    padding-bottom: 16px;
  }

  &__content {
    width: calc(100% + 32px);
    background-color: rgba($black-haze, 0.5);
    border-bottom: 1px solid $silver-sand;
    border-top: 1px solid $silver-sand;
    padding-top: 16px;
    padding-bottom: 16px;

    @media (max-width: $screen-xs-max) {
      margin-left: -16px;
      margin-right: -14px;
      padding-left: 16px;
      padding-right: 14px;
    }

    @media (min-width: $screen-sm-min) {
      @include side-spacing(padding, left, 32px);
      @include side-spacing(padding, right, 14px);
      @include side-spacing(margin, left, -32px);
    }
  }

  &__footer {
    display: flex;
    background-color: $white;
    color: var(--color-primary);
    padding: 16px 0;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $screen-xs-max) {
      &__toggle .icon {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    &__toggle {
      cursor: pointer;
    }
  }

  &__title,
  &__footer {
    &--fixed {
      position: fixed;
      margin: 0px -32px;
      z-index: $zindex-profile-sticky;
      @include tabs-content-container(32px, 16px 0px);

      @media (max-width: $screen-xs-max) {
        @include side-spacing(margin, left, -16px);
      }
    }
  }

  &__title--fixed {
    top: 0;
    padding: 16px 0;
    box-shadow: -5px 4px 7px 0 rgba(0, 0, 0, 0.1);

    @media (max-width: $screen-xs-max) {
      margin: 0;
      padding: 16px;
    }

    @media (min-width: $screen-sm-min) {
      @include side-spacing(padding, left, 32px);
    }
  }

  &__footer--fixed {
    bottom: 0;
    border-top: 1px solid $silver-sand;

    @media (max-width: $screen-xs-max) {
      margin: 0;
      padding: 16px;
      box-shadow: 2px 0 8px 0 rgba(0, 0, 0, 0.23);
    }

    @media (min-width: $screen-sm-min) {
      .profile-section__footer__toggle {
        @include side-spacing(padding, left, 32px);
      }
    }
  }

  &__footer-wrapper {
    width: 100%;
  }

  .ReactCollapse--collapse {
    @include side-spacing(padding, left, 32px);
    @include side-spacing(margin, left, -32px);
  }
}
