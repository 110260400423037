@import "../../styles/colors";

.radio-switch {
  border: 1px solid $silver-sand;

  &:hover {
    border-color: var(--color-primary);
  }

  &.radio-switch--disabled {
    background-color: $black-haze;
    border-color: $silver-sand;
  }

  &--checked {
    border-color: var(--color-primary);

    &:before {
      content: "";
      background-color: var(--color-primary);
    }

    &:hover {
      border-color: var(--color-primary-hover);

      &:before {
        background-color: var(--color-primary-hover);
      }
    }

    &.radio-switch--error {
      border-color: var(--color-danger-alt);

      &:before {
        background-color: var(--color-danger-alt);
      }

      &:hover {
        border-color: var(--color-danger-alt-hover);

        &:before {
          background-color: var(--color-danger-alt-hover);
        }
      }
    }

    &.radio-switch--disabled {
      &:before {
        background-color: $gray-chateau;
      }

      &:hover {
        border-color: $gray-chateau;
      }
    }
  }
}
