@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../styles/mixins/typography";

@mixin mobile-card-image {
  mask-size: 24px;
  mask-repeat: no-repeat;
  mask-position: center;
}

.mobile-card {
  height: 204px;
  max-width: 470px;
  border: 1px solid $mystic;
  border-radius: 3px;
  background-color: $black-haze;
  color: $mine-shaft;

  &__internet-paused {
    border: 1px solid var(--color-danger);
  }

  & &__header {
    @include side-spacing(padding, right, 0);
    color: $white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 64px;
    margin-bottom: 15px;
  }

  &__info {
    @include side-spacing(margin, left, 16px);
    overflow: hidden;
  }

  &__title {
    @include ellipsis();
    white-space: pre;
  }

  &__image {
    @include side-spacing(margin, left, 24px);
    height: 24px;
    flex: 0 0 24px;
    background-color: $white;
  }

  &__content {
    padding: 0 24px 20px;

    @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
      padding: 0 64px 20px;
    }
  }

  &__protection-level {
    margin-bottom: 4px;
    @include capitalize;
  }

  &.default__theme {
    header {
      background-color: $mid-gray;
    }

    .mobile-card__image {
      mask: url("../../../../styles/images/default-card.svg");
      @include mobile-card-image;
    }
  }

  &.adults__theme {
    header {
      background-color: var(--color-primary);
    }

    .mobile-card__image {
      mask: url("../../../../styles/images/light-age-group-card.svg");
      @include mobile-card-image;
    }
  }

  &.teens__theme {
    header {
      background-color: var(--color-accent-highlight);
    }

    .mobile-card__image {
      mask: url("../../../../styles/images/medium-age-group-card.svg");
      @include mobile-card-image;
    }
  }

  &.kids__theme {
    header {
      background-color: var(--color-accent-light);
    }

    .mobile-card__image {
      mask: url("../../../../styles/images/strict-age-group-card.svg");
      @include mobile-card-image;
    }
  }

  &__button {
    width: 100%;
    margin-top: 20px;
  }

  &__arrow-button {
    @include side-spacing(margin, left, auto);
    text-decoration: none;

    button {
      padding: 24px 24px 20px;
      cursor: pointer;
      border: 0;
      background-color: transparent;
      color: $white;

      &:focus {
        outline: none;
      }
    }
  }
}
