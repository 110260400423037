.categories-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: -12px;

  &__item {
    margin-bottom: 12px;
  }

  .accordion-column {
    min-width: 288px;
  }
}
