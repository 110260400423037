@import "../../../styles/mixins/typography";
@import "../../../styles/colors";

.modal__dialog.mobile-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 288px;
  height: auto;
  border: 1px solid $black-haze;
  border-radius: 12px;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.1);

  .modal__header {
    display: block;
    text-align: center;
    @include font-weight("semi-bold");
    border-color: $black-haze;
  }

  .modal__body {
    display: block;
    padding: 8px 24px;
  }

  .modal__footer {
    padding: 0;
    box-shadow: none;

    .modal__control {
      padding: 16px 24px;
      line-height: 24px;
      background-color: transparent;
      border: none;
      color: var(--color-main);
    }
  }
}
