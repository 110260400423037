@import "../../../../styles/variables";
@import "../../../../styles/mixins/typography";

.protection-choice {
  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 600px;

    @media (max-width: $screen-xs-max) {
      min-width: 100%;
    }
  }

  &__content-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @media (max-width: $screen-xs-max) {
      margin: 30px 0 20px;
    }
  }

  &__subtitle {
    @include italic;
    @include font-weight("light");
    padding: 5px;
    margin-bottom: 20px;
  }

  &__description {
    line-height: 20px;
    @include font-weight("light");
    text-align: center;
    width: 60%;
  }

  &__button {
    margin: 30px auto 80px;
    display: block;
    width: 100%;
    max-width: 185px;
    height: 40px;

    @media (max-width: $screen-xs-max) {
      order: 1;
      max-width: 280px;
      margin: 30px auto 0;
    }
  }

  &__image {
    height: 220px;
    width: 100%;
    padding: 0 30px;

    @media (max-width: $screen-xs-max) {
      height: 140px;
    }
  }
}
