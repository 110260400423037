@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/typography";

.network-id {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 24px);

  &--merged {
    display: flex;
    align-items: center;

    .tooltip-referrer {
      @include side-spacing(margin, left, 8px);
      padding: 4px 0;
    }
  }

  &-row:first-child {
    margin-bottom: 4px;
  }

  &__icon {
    font-size: 14px;
    color: $mid-gray;
  }

  &__manufacturer {
    margin-bottom: 2px;
  }

  &__manufacturer,
  &__identifier {
    @include ellipsis();
  }

  &__identifier:not(:last-child) {
    margin-bottom: 4px;
  }
}
