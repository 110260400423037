@import "../../../../../styles/colors";
@import "~@sportal/cdk/styles/mixins/direction";

.devices-counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &__amount {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 0 6px;

    $radius: 12px;
    height: $radius * 2;
    min-width: $radius * 2;
    border-radius: $radius;
  }

  &__label {
    flex: 1 0 auto;
    @include side-spacing(margin, left, 10px);
  }

  &--outlined {
    color: white;

    .devices-counter__amount {
      border: 1px solid $white;
      background-color: transparent;
    }
  }

  &--primary {
    color: var(--color-primary);

    .devices-counter__amount {
      color: $white;
      background-color: var(--color-primary);
    }
  }

  &--secondary {
    color: $mid-gray;

    .devices-counter__amount {
      color: $white;
      background-color: $mid-gray;
    }
  }

  & + & {
    @include side-spacing(margin, left, 16px);
  }
}
