@import "../colors";

// fonts-size
$size: (
  "tiny": 10px,
  "small": 12px,
  "regular": 14px,
  "medium": 16px,
  "large": 18px,
  "x-large": 20px,
  "extra-large": 24px,
  "jumbo": 30px,
);

//line-height
$line-height: (
  "tiny": 16px,
  "small": 18px,
  "regular": 20px,
  "medium": 24px,
  "large": 26px,
  "x-large": 30px,
  "extra-large": 32px,
  "jumbo": 45px,
);

// font-weights
$weights: (
  "light": 300,
  "normal": 400,
  "semi-bold": 600,
  "bold": 700,
  "extra-bold": 800,
);

@mixin font-size($name) {
  font-size: map-get($size, $name);
  line-height: map-get($line-height, $name);
}

@mixin font-weight($name) {
  font-weight: map-get($weights, $name);
}

// transformations
@mixin uppercase {
  text-transform: uppercase;
}

@mixin capitalize {
  text-transform: capitalize;
}

@mixin inverted-txt {
  color: $white;
}

@mixin italic {
  font-style: italic;
}

@mixin gray-txt {
  color: $mid-gray;
}

@mixin hint-txt {
  @include italic;
  color: $dusty-gray;
}

@mixin error-txt {
  @include font-size(small);
  color: var(--color-danger);
}

@mixin ellipsis($white-space: nowrap) {
  white-space: $white-space;
  text-overflow: ellipsis;
  overflow: hidden;
}
