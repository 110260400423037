@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/colors";
@import "../../styles/mixins/typography";

//bg, color, hover-lightness, active-lightness
$primary: ("primary", $picton-blue, $white, 10, -10);
$success: ("success", $java, $white, 10, -10);
$danger: ("danger", $scarlet, $white, 10, -10);
$warning: ("warning", $california, $white, 10, -6);
$secondary: ("secondary", $white, $mine-shaft, -5, -10);

$outline-primary: ("primary", $picton-blue, -20, -13);
$outline-success: ("success", $java, -20, -13);
$outline-danger: ("danger", $scarlet, -20, -13);
$outline-warning: ("warning", $california, -20, -13);
$outline-secondary: ("secondary", $white, -20, -13);

@function applyLightness($color, $value) {
  @if $value > 0 {
    @return lighten($color, $value);
  } @else {
    @return darken($color, -$value);
  }
}

@mixin base-button($bg, $color, $hover-lightness, $active-lightness) {
  color: $color;
  background-color: $bg;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: applyLightness($bg, $hover-lightness);
  }
  &:active:not(:disabled) {
    background-color: applyLightness($bg, $active-lightness);
  }
}

@mixin outlined-variant($color, $hover-lightness, $active-lightness) {
  background-color: transparent;
  color: $color;
  border: 1px solid $color;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-color: applyLightness($color, $hover-lightness);
  }
  &:active:not(:disabled) {
    border-color: applyLightness($color, $active-lightness);
  }
}

@mixin inverted-variant($color) {
  background-color: transparent;
  border: 1px solid $color;
  color: $white;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-color: applyLightness($color, -15);
    background-color: rgba(0, 0, 0, 0.15);
  }

  &:active:not(:disabled) {
    border-color: applyLightness($color, -20);
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background-color: rgba(255, 255, 255, 0.08);
  }
}

.button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border: none;

  min-width: 60px;
  border-radius: 3px;

  outline: none;
  cursor: pointer;
  @include font-size(regular);

  &__content {
    position: relative;
    display: block;

    &--icon-left {
      @include side-spacing(padding, left, 24px);
    }

    &--icon-right {
      @include side-spacing(padding, right, 24px);
    }

    &--ellipsis {
      @include ellipsis();
    }
  }

  &__icon {
    position: absolute;
    display: flex;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);

    &--left {
      @include fixed-position(left, 0);
    }

    &--right {
      @include fixed-position(right, 0);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &--small {
    padding: 0 16px;
    height: 26px;
  }

  &--medium {
    padding: 0 16px;
    height: 32px;
  }

  &--large {
    padding: 0 16px;
    height: 40px;
  }

  &--secondary {
    border: 1px solid $silver-sand;
  }

  @each $variant, $bg, $color, $hover-lightness, $active-lightness in $secondary,
    $primary, $warning, $success, $danger
  {
    &--#{$variant} {
      @include base-button($bg, $color, $hover-lightness, $active-lightness);
    }
  }
  @each $variant, $color, $hover-lightness,
    $active-lightness in $outline-primary, $outline-success, $outline-danger,
    $outline-warning, $outline-secondary
  {
    &--#{$variant}--outlined {
      @include outlined-variant($color, $hover-lightness, $active-lightness);
    }

    &--#{$variant}--inverted {
      @include inverted-variant($color);
    }
  }
}
