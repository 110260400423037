@import "../../../styles/colors";
@import "../../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/variables";

.compact-service-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12.5px 15px;
  color: $white;

  @media (max-width: $screen-xs-max) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__status {
    @include side-spacing(margin, left, 20px);
  }
}
