@import "../../../../styles/colors";
@import "../../../../styles/mixins/typography";

.multiple-wizard-sidebar {
  color: $white;

  &__title {
    margin-bottom: 6px;
  }

  &__info {
    margin-bottom: 16px;
  }

  &__description {
    @include font-size("small");
  }
}
