@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/variables";

.device-code-hint {
  @media (max-width: $screen-xs-max) {
    padding: 16px 8px 0;

    .icon {
      @include side-spacing(margin, right, 6px);
    }

    .button-link {
      display: block;
      margin-top: 8px;
    }
  }

  @media (min-width: $screen-sm-min) {
    display: flex;
    flex-wrap: nowrap;

    .icon {
      position: relative;
      top: 2px;
    }

    &__content {
      @include side-spacing(margin, left, 8px);
    }

    &__text {
      @include side-spacing(margin, right, 8px);
    }
  }
}
