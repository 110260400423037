@import "~@sportal/cdk/styles/mixins/direction";
@import "./../../../styles/variables";

.login-form {
  &__button {
    margin-top: 8px;
    align-self: flex-end;
    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }
}
