@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/colors";
@import "../../../styles/variables";

.scheduler-categories {
  margin-top: 24px;

  .categories-list {
    margin-top: 16px;
  }
}
