@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/variables";

.profile-section__header-form {
  display: flex;

  @media (min-width: $screen-sm-min) {
    align-items: center;
  }

  .switcher {
    @include side-spacing(margin, left, auto);

    @media (min-width: $screen-sm-min) {
      @include side-spacing(margin, left, 16px);
    }

    @media (min-width: $screen-md-min) {
      @include side-spacing(margin, left, 24px);
    }
  }
}
