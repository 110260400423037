@import "~@sportal/cdk/styles/mixins/direction";
@import "./../../../styles/mixins/typography";
@import "./../../../styles/colors";

.settings-navbar-wrapper {
  background-color: $black-haze;
}

.settings-navigation,
.mb-settings-navigation {
  @mixin svg-image {
    mask-repeat: no-repeat;
    mask-position: center;
  }

  .image__account {
    mask: url("../../../styles/images/user.svg");
    @include svg-image();
  }

  .image__lists {
    mask: url("../../../styles/images/block.svg");
    @include svg-image();
  }

  .image__block {
    mask: url("../../../styles/images/protection-settings-icon.svg");
    @include svg-image();
  }

  .image__devices {
    mask: url("../../../styles/images/icon-devices-white.svg");
    @include svg-image();
  }
}

.settings-navigation {
  & &__button {
    text-decoration: none;
    color: $white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 4px;

    &--active {
      position: relative;
      background-color: var(--color-primary);
    }
  }

  & &__tab-title {
    @include font-size(large);
    @include font-weight(normal);
  }

  & &__image {
    display: flex;
    align-items: center;
    height: 56px;
    width: 56px;

    margin-top: 4px;
    background-color: $white;
  }
}

.mb-settings-navigation {
  & &__button {
    display: flex;
    align-items: center;
    padding: 14px 8px 11px;
    color: $mine-shaft;
    white-space: nowrap;
    text-decoration: none;

    &--active {
      position: relative;
      border-bottom: 3px solid var(--color-primary);
      @include font-weight(semi-bold);

      .mb-settings-navigation__image {
        background-color: $mine-shaft;
      }
    }
  }

  & &__tab-title {
    @include font-size(regular);
    @include font-weight(regular);
  }

  & &__image {
    height: 20px;
    width: 20px;
    @include side-spacing(margin, right, 8px);
    background-color: $mid-gray;
  }
}
