@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../../styles/mixins/typography";

.cell-details-mobile {
  width: 100%;

  &.td.td--details {
    margin-top: 12px;
  }

  &__header {
    @include font-size("regular");
    @include font-weight("semi-bold");
    margin-bottom: 2px;
  }

  &__header,
  &__value {
    @include ellipsis();
  }
}
