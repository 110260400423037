@import "../../../../styles/mixins/typography";
@import "../../../../styles/variables";
@import "~@sportal/cdk/styles/mixins/direction";

.multiple-wizard-devices-page {
  &__wizard-hint {
    margin-bottom: 16px;

    @media (max-width: $screen-xs-max) {
      margin-top: 24px;
    }
  }

  &__content {
    margin-bottom: 32px;
  }

  &__control-bar {
    margin-bottom: 8px;

    @media (max-width: $screen-sm-max) {
      margin-top: 24px;
    }
  }
}
