@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/colors";
@import "../../styles/mixins/typography";

.url-list {
  &__header {
    color: $mine-shaft;
    @include font-size(regular);
    @include font-weight(semi-bold);
  }

  &__content__empty-list {
    @include hint-txt;
  }

  &__item {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 4px;
    background-color: $black-haze;
    border-radius: 3px;
  }

  &__url {
    @include ellipsis;
    @include side-spacing(padding, right, 8px);
  }

  .fa-ban {
    color: var(--color-danger);
  }

  .fa-check {
    color: var(--color-success);
  }

  .button--custom {
    color: var(--color-primary);
    background: transparent;
    cursor: pointer;
  }
}
