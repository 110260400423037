@import "../../styles/variables";
@import "../../styles/colors";
@import "../../styles/mixins/grid";
@import "~@sportal/cdk/styles/mixins/direction";

.home-service-panel {
  position: relative;
  background-color: var(--color-accent-dark);

  @media (min-width: $screen-md-min) {
    border-bottom: 1px solid var(--color-accent-midtone);
  }

  & &__container {
    @include adaptive($screen-xs-max, $screen-sm-max, $screen-md-max);
    margin: 0 auto;

    @media (min-width: $screen-md-min) {
      display: flex;
      flex-wrap: nowrap;

      .detailed-service-panel {
        flex: 1;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .detailed-service-panel {
      border-bottom: 1px solid var(--color-accent-midtone);
    }
  }

  .detailed-service-panel + .detailed-service-panel {
    @media (min-width: $screen-md-min) {
      @include side-spacing(
        border,
        left,
        2px solid var(--color-accent-midtone)
      );
    }
  }
}
