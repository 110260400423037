@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../spsonPanels";

.spson-compact-panel {
  @include spson-panel-base();
  padding: 8px 16px;
  margin-bottom: 16px;

  h3 {
    margin-bottom: 3px;
  }

  &__actions {
    @include side-spacing(margin, left, 24px);
  }

  @media (max-width: $screen-sm-max) {
    padding: 16px 24px;
  }

  @media (max-width: $screen-xs-max) {
    padding: 16px 12px;
  }

  &__roaming-limit {
    font-size: 12px;
    line-height: 17px;
  }
}
