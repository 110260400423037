@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../../../styles/colors";

.mobile-modal-list-body {
  list-style: none;
  margin: -8px 0;

  &__item {
    &#{&}--disabled {
      .icon,
      .mobile-modal-list-body__item-button {
        color: $gray-chateau;
      }
    }

    .icon-wrapper {
      display: block;
      @include side-spacing(margin, right, 8px);
    }

    .icon-wrapper,
    .icon {
      height: 24px;
      width: 24px;
    }

    .icon {
      color: $mid-gray;
    }

    &-button {
      color: var(--color-main);
      padding: 8px 0;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      @include fixed-position(text-align, left);

      &--sorted {
        font-weight: 600;
      }
    }
  }
}
