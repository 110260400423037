@import "../../../styles/variables";
@import "~@sportal/cdk/styles/mixins/direction";

.multiple-content {
  &__add-device {
    @media (min-width: $screen-sm-min) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__add-device-button {
    @media (max-width: $screen-xs-max) {
      margin-top: 12px;
      width: 100%;
    }

    @media (min-width: $screen-sm-min) {
      @include side-spacing(margin, left, 16px);
    }
  }
}
