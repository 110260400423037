@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/mixins/typography";
@import "./CommonHeaderUserMenu";

$menu-width: 260px;
$slide-duration: 300ms;
$icon-size: 20px;

.user-menu-sidebar {
  &__toggle {
    cursor: pointer;

    .icon {
      width: $icon-size;
      height: $icon-size;
      font-size: $icon-size;
    }
  }

  &__menu {
    position: fixed;
    top: 0;
    width: $menu-width;
    @include fixed-position(right, -$menu-width);
    height: 100%;
    padding-top: 16px;
    background-color: $white;
    box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.1);
    z-index: $zindex-mobile-menu;
    transition: left $slide-duration ease, right $slide-duration ease;

    ul {
      list-style-type: none;
    }

    &__item {
      display: flex;
      @include font-size(medium);
      @include item-divider;

      &.clear-data {
        &::before,
        &::after {
          width: calc(100% - 32px);
          left: 16px;
        }
      }

      &--action-item {
        cursor: pointer;

        &:hover .user-menu-sidebar__menu__item__subscriber-name {
          color: $white;
        }
      }

      &--action-item,
      &--link a {
        color: $mine-shaft;
        padding: 12px 24px;

        &:hover,
        &:focus {
          outline: none;
          color: $white;
          background-color: var(--color-primary);
        }
      }

      &--link a {
        text-decoration: none;
        width: 100%;
      }

      &__subscriber-name {
        display: flex;
        align-items: center;
        @include side-spacing(margin, left, 8px);
        @include font-size(regular);
        color: $dusty-gray;
      }
    }

    &__title {
      @include font-size(tiny);
      @include uppercase;
      color: $dusty-gray;
      margin: 16px 0 4px;
      @include side-spacing(margin, left, 24px);
    }
  }

  &:before {
    content: "";
    position: fixed;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $white;
    z-index: $zindex-mobile-menu-backdrop;
    opacity: 0;
    transition: opacity $slide-duration ease, width $slide-duration step-end;
  }

  &--opened {
    &:before {
      width: 100%;
      opacity: 0.7;
      transition: opacity $slide-duration ease, width $slide-duration step-start;
    }

    .user-menu-sidebar__menu {
      @include fixed-position(right, 0);
    }
  }
}
