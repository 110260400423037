@import "../../styles/colors";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/typography";
@import "./../../styles/variables";
@import "~@sportal/cdk/styles/mixins/direction";

.report {
  display: flex;
  background-color: var(--color-accent-dark);

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    @include adaptive($screen-xs-max, $screen-sm-max, $screen-md-max);

    padding: 20px 24px;
    margin: 0 auto;

    @media (max-width: $screen-xs-max) {
      padding: 10px 17px;
    }

    &--inner {
      width: 100%;
    }
  }

  &__title {
    color: $white;
    margin: 0 0 20px;
  }

  &__controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__time-selector {
    white-space: nowrap;

    .button {
      @include ellipsis();
      flex: 1;
    }

    .button:not(:last-child) {
      @include side-spacing(margin, right, 8px);
    }

    @media (max-width: $screen-xs-max) {
      width: 100%;
      display: flex;
    }

    @media (min-width: $screen-sm-min) {
      @include side-spacing(margin, right, 60px);
    }
  }

  &__toggles {
    display: flex;
    color: $white;

    .switch-base--disabled {
      color: $dusty-gray;
    }

    .switch-base {
      &:hover,
      &:active,
      &:focus-within {
        .checkbox-switch {
          border-color: var(--color-report-activity-hover);

          &.checkbox-switch--error {
            border-color: var(--color-report-malware-hover);
          }
          &.checkbox-switch--warning {
            border-color: var(--color-report-blocks-hover);
          }

          &.checkbox-switch--disabled {
            background-color: $gray-chateau;
            border-color: $gray-chateau;
          }
        }

        .checkbox-switch--checked {
          background-color: var(--color-report-activity-hover);

          &.checkbox-switch--error {
            background-color: var(--color-report-malware-hover);
          }
          &.checkbox-switch--warning {
            background-color: var(--color-report-blocks-hover);
          }

          &.checkbox-switch--disabled {
            background-color: $gray-chateau;
            border-color: $gray-chateau;
          }
        }
      }
    }

    .checkbox-switch {
      &--checked {
        background-color: var(--color-report-activity);
        border-color: var(--color-report-activity);

        &.checkbox-switch--error {
          background-color: var(--color-report-malware);
        }
        &.checkbox-switch--warning {
          background-color: var(--color-report-blocks);
        }
      }

      &--error {
        border-color: var(--color-report-malware);
      }

      &--warning {
        border-color: var(--color-report-blocks);
      }

      &--disabled {
        background-color: $gray-chateau;
        border-color: $gray-chateau;
      }
    }
    @media (max-width: $screen-xs-max) {
      flex-direction: column;

      .switch-base {
        margin-bottom: 8px;
      }
    }

    @media (min-width: $screen-sm-min) {
      .switch-base:not(:last-child) {
        @include side-spacing(margin, right, 26px);
      }
    }
  }

  &__chart {
    position: relative;
    width: 100%;
    margin: 24px 0 0;

    svg {
      width: 100%;
      height: 165px;
      overflow: visible;
    }

    &--activity-hidden .activity-group,
    &--blocks-hidden .blocks-group,
    &--ss-hidden .ss-group {
      display: none;
    }

    /*&--activity-hidden .blocks {transform: scaleY(2) translate(0, -55px);}*/

    .xAxis {
      .domain {
        stroke: $white;
        stroke-width: 1px;
      }

      .tick {
        @include font-size("tiny");
        text {
          fill: $gray-chateau;
        }
      }
    }

    .message {
      text-anchor: middle;
      alignment-baseline: middle;

      @include uppercase;
      letter-spacing: 0.2px;
      fill: $gray-chateau;
      transform: translateY(-20px);
    }

    .rect-stack--hover {
      fill: white;
      transition: fill ease-in 0.2s;
    }
  }

  &__tooltip {
    z-index: $zindex-popover;
    position: absolute;
    top: 0;
  }

  .profile-page & {
    border-top: 1px solid var(--color-accent-midtone);
  }
}
