@import "../../../../../styles/colors";

.profile-device-menu {
  &__remove-item {
    color: var(--color-danger);

    @at-root .dropdown__item--highlighted#{&} {
      color: $white;
    }
  }
}
