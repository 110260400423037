@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/colors";
@import "../../styles/mixins/typography";

.paused-internet {
  display: flex;
  align-items: center;
  min-height: 32px;
  text-align: left;
  border-radius: 3px;
  background: var(--color-danger);
  color: $white;
  -webkit-tap-highlight-color: transparent;

  &--interactive {
    height: 40px;
    text-align: center;
    cursor: pointer;
  }

  &__label {
    @include font-size(small);
    margin-bottom: 4px;
  }

  &__time-left {
    flex: 1;
    padding: 4px 16px;
    overflow-wrap: anywhere;

    &-value {
      @include font-weight(semi-bold);
      white-space: nowrap;
    }
  }

  &__unpause-button {
    color: $white;
    text-decoration: none;
    border: none;
    outline: 0;
    background-color: transparent;
    height: 100%;
    padding: 0 8px;
    cursor: pointer;
  }
}
