@import "../../../styles/colors";
@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/variables";

@mixin notification-variant($background, $icon) {
  background-color: $background;

  .notification__body:before {
    content: $icon;
  }
}

.notification {
  backface-visibility: hidden; // when temporary notifications are applied by React.portal fonts on Google Chrome get blurred, this rule prevents it
  color: $white;

  &.fade-exit-active {
    pointer-events: none;
  }

  &__body {
    position: relative;
    min-height: 48px;

    &:before {
      position: absolute;
      top: 13px;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      font-size: 24px;
      line-height: normal;
    }
  }

  &__close-button {
    top: 0;
    position: absolute;
    color: $white;
  }

  &__content {
    @media screen and (min-width: $screen-xs-min) {
      @include side-spacing(margin, right, 48px);
    }

    @media screen and (min-width: $screen-md-min) {
      @include side-spacing(margin, right, 144px);
    }
  }

  &--error {
    @include notification-variant(var(--color-danger), "\f057");
  }
  &--warning {
    @include notification-variant(var(--color-warning), "\f06a");
  }
  &--info {
    @include notification-variant(var(--color-primary), "\f05a");
  }
  &--success {
    @include notification-variant(var(--color-success), "\f058");
  }

  a {
    font-weight: 700;
    color: $white;
  }
}
