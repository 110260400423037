@import "../../styles/variables";
@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/colors";

body.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  display: block;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(17, 17, 17, 0.7);
  z-index: $modal-layout;
  outline: none;

  @media (min-width: $screen-sm-min) {
    overflow-y: auto;
  }

  &__hint {
    margin-bottom: 16px;
  }

  &__dialog {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border: 1px solid $black-haze;

    @media (min-width: $screen-sm-min) {
      position: relative;
      top: 12%;
      max-width: 408px;
      margin: 0 auto;
      padding: 24px;
      border-radius: 6px;
      box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.1);
    }

    @media (max-width: $screen-xs-max) {
      height: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 auto;

    @media (max-width: $screen-xs-max) {
      padding: 16px;
      border-bottom: 1px solid $silver-sand;
    }

    &__cross-button {
      @include side-spacing(margin, right, -8px);
      color: $mine-shaft;
    }
  }

  &__body {
    flex: 1 1 100%;

    @media (min-width: $screen-sm-min) {
      min-height: 0;
      margin-top: 16px;
      margin-bottom: 32px;
    }

    @media (max-width: $screen-xs-max) {
      padding: 16px;
      overflow-y: auto;
    }
  }

  &__footer {
    display: flex;
    flex: 1 0 auto;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
      padding: 16px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      border-top: 1px solid $black-haze;
    }

    @media (min-width: $screen-sm-min) {
      justify-content: flex-end;
    }
  }

  .modal__control + .modal__control {
    @media (max-width: $screen-xs-max) {
      margin-top: 8px;
    }

    @media (min-width: $screen-sm-min) {
      @include side-spacing(margin, left, 8px);
    }
  }
}
