@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/mixins/typography";

.pause-internet {
  position: relative;

  &__trigger {
    width: 100%;
    -webkit-tap-highlight-color: transparent;

    &.button {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon--reverse {
        transform: rotateX(180deg) translateY(50%);
      }
    }
  }

  .dropdown__toggle {
    width: 100%;
  }

  .dropdown__menu {
    @include capitalize;
    @include float-position(text-align, left);
  }
}
