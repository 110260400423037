@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/colors";

.go-back {
  &__button {
    color: $white;
    text-decoration: none;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: $black-haze;
    }
  }

  &__content {
    @include side-spacing(margin, left, 9px);
  }

  &__icon {
    .rtl & {
      transform: rotate(180deg);
    }
  }
}
