@import "../variables";

@mixin stretch {
  display: flex;
  flex: 1 1 100%;
}

@mixin adaptive($sm, $md, $lg, $property: "width") {
  @media (min-width: $screen-sm-min) {
    & {
      #{$property}: $sm;
    }
  }

  @media (min-width: $screen-md-min) {
    & {
      #{$property}: $md;
    }
  }

  @media (min-width: $screen-lg-min) {
    & {
      #{$property}: $lg;
    }
  }
}
