@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "./CommonHeaderUserMenu";

.user-menu-dropdown {
  .dropdown__item {
    @include item-divider;
  }

  &__toggle {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;

    &__name,
    .icon {
      color: $white;
    }

    &__name {
      @include side-spacing(padding, right, 8px);
    }
  }
}
