@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/colors";
@import "../../../styles/mixins/typography";

.profile-devices-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;

  &__header-row {
    .profile-devices-table__header {
      @include font-weight("semi-bold");
      text-align: left;
    }
  }

  &__header,
  &__cell {
    &:not(&--hidden) {
      padding-top: 12px;
    }

    &--hidden {
      width: 0;
    }

    &--device_type {
      width: 20px;
      @include side-spacing(padding, left, 16px);
    }

    &--device_name,
    &--network_id {
      width: 50%;
      max-width: 0;
      @include side-spacing(padding, right, 16px);
    }

    &--device_name {
      @include ellipsis();
      @include side-spacing(padding, left, 8px);
    }
  }

  &__row {
    min-height: 64px;
    background-color: $black-haze;

    + .profile-devices-table__row {
      margin-top: 16px;
    }
  }

  &__cell {
    &:not(&--hidden) {
      padding-bottom: 12px;
    }

    &--device_type {
      border-radius: 3px 0 0 3px;

      .icon {
        color: $mid-gray;
      }

      .tooltip-referrer {
        padding: 4px 0;
      }
    }

    &--network_id {
      border-radius: 0 3px 3px 0;
    }
  }
}
