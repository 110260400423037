@import "../../../../../styles/variables";

.add-device-button {
  @media (max-width: $screen-xs-max) {
    width: 100%;
    // TODO: workaround for centering button content when button width greater than content
    //  would be great to fix it in button
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
}
