@import "~@sportal/cdk/styles/mixins/direction";

.list-notification {
  list-style: none;

  &__item {
    position: relative;
    @include side-spacing(padding, left, 33px);

    &:before {
      content: "•";
      @include fixed-position(left, 14px);
      position: absolute;
    }
  }
}
