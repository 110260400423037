@import "../../styles/colors";

.roaming-icon {
  display: inline-flex;
  width: 20px;
  color: $mid-gray;

  &::before {
    content: "R";
    font-size: 10px;
    position: relative;
    top: -6px;
    left: 2px;
  }

  &__bars {
    position: relative;
    top: 2px;
    left: -6px;
  }
}

.rtl .roaming-icon {
  &::before {
    left: -10px;
  }

  &__bars {
    left: 7px;
  }
}
