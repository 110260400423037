@import "../../styles/colors";
@import "../../styles/mixins/typography";
@import "../../styles/variables";
@import "~@sportal/cdk/styles/mixins/direction";

//collapsible section
.report-panel {
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    color: $white;
    cursor: pointer;

    &:before {
      @include font-size("small");
      font-family: "Font Awesome 5 Pro";
      font-weight: 400;
      content: "\f078";
      color: $gray-chateau;
    }
  }

  &__title {
    word-break: break-word;
    @include font-size("medium");

    @include side-spacing(margin, right, 20px);
    @include side-spacing(margin, left, 10px);

    @media (max-width: $screen-xs-max) {
      @include font-size("regular");
    }
  }

  &__icon {
    width: 30px;
    height: 20px;
    @include side-spacing(margin, left, auto);

    background-image: url("../../styles/images/reports.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .report-panel__content {
    padding-top: 16px;
  }

  &.collapsible-section--opened {
    .report-panel__header:before {
      transform: rotateZ(180deg);
    }
  }
}
