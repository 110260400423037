@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/typography";

.tooltip {
  max-width: 200px;
  @include font-size("small");
  padding: 6px 8px;
  background-color: $white;
  z-index: $zindex-tooltip;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.23);
  border: 1px solid $mystic;
  border-radius: 3px;

  .tooltip__arrow:before {
    background-color: $white;
    border-style: solid;
    border-color: $mystic;
    border-width: 0;
  }

  &[data-popper-placement^="top"] {
    .tooltip__arrow {
      &:before {
        box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
        border-bottom-width: 1px;
        border-right-width: 1px;
      }
    }
  }

  &[data-popper-placement^="bottom"] {
    .tooltip__arrow {
      &:before {
        box-shadow: -2px -2px 2px 0 rgba(0, 0, 0, 0.1);
        border-top-width: 1px;
        border-left-width: 1px;
      }
    }
  }

  &[data-popper-placement^="left"] {
    .tooltip__arrow {
      &:before {
        box-shadow: 2px -2px 2px 0 rgba(0, 0, 0, 0.1);
        border-top-width: 1px;
        border-right-width: 1px;
      }
    }
  }

  &[data-popper-placement^="right"] {
    .tooltip__arrow {
      &:before {
        box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.1);
        border-bottom-width: 1px;
        border-left-width: 1px;
      }
    }
  }
}
