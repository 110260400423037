@import "../../styles/colors";

.tab-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 42px;

  padding-bottom: 16px;
  border-bottom: 1px solid $silver-sand;
  margin-bottom: 24px;
}
