@import "../../../styles/colors";
@import "../../../styles/variables";

.protection-choice {
  &__container {
    display: flex;
    color: $white;

    @media (max-width: $screen-xs-max) {
      flex-flow: column nowrap;
    }
  }

  &__item--multiple &__content-container,
  &__item--single &__content-container {
    min-height: 180px;

    @media (max-width: $screen-xs-max) {
      min-height: 0;
    }
  }

  &__item--single-option &__content-container {
    min-height: 0;
  }

  &__item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    flex: 1;
    min-height: 100vh;
    padding: 0 15px;

    @media (max-width: $screen-xs-max) {
      min-height: 50vh;
      padding: 0 15px 30px;
    }

    &--multiple {
      background-color: var(--color-primary);
      align-items: flex-end;

      @media (max-width: $screen-md-max) {
        align-items: center;
      }
    }

    &--single {
      background-color: $mid-gray;

      img {
        padding: 0 85px;

        @media (max-width: $screen-xs-max) {
          padding: 0;
        }
      }
    }

    &--single-option {
      align-items: center;
      justify-content: center;
      min-height: 100vh;

      @media (max-width: $screen-xs-max) {
        justify-content: flex-start;
      }
    }
  }
}
