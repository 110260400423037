@import "../../styles/colors";
@import "../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/colors";
@import "~@sportal/cdk/styles/mixins/direction";

.input {
  // TODO: this reserves a space for the error
  //  it should be synced with &__errors size (now it is line-height)
  //  also not that @cdk version has own values for it,
  //  it may make sense to refactor this to make it more solid
  padding-bottom: 18px;
  margin-bottom: 8px;

  &__field {
    margin: 4px 0 2px;

    .icon {
      color: $dusty-gray;
      height: 40px;
      width: 40px;
    }
  }

  .base-input {
    border: 1px solid $silver-sand;
    background-color: $white;
    border-radius: 3px;
    padding-top: 11px;
    padding-bottom: 11px;
    height: 40px;
    outline: none;
    @include side-spacing(padding, right, 40px);
    @include side-spacing(padding, left, 16px);

    &::placeholder {
      @include hint-txt;
      font-weight: 200;
    }

    &:focus {
      box-shadow: inset 1px 1px 0 0 rgba(0, 0, 0, 0.08),
        0 0 8px 0 rgba(var(--color-primary-rgb), 0.6);
      border-color: var(--color-primary);
    }

    &:disabled {
      background-color: $black-haze;
    }
  }

  &#{&}--has-icon .base-input {
    @include side-spacing(padding, left, 40px);
  }

  &--has-error {
    padding-bottom: 0;

    .base-input {
      border-color: var(--color-danger);

      &:focus {
        box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.08),
          0 0 8px 0 rgba(var(--color-danger-rgb), 0.51);
        border-color: var(--color-danger);
      }
    }
  }

  .cross-button {
    border-radius: 3px;
  }

  &__error {
    color: var(--color-danger);
  }

  &__error,
  &__label,
  &__hint {
    @include font-size(small);
  }
}
