@import "../../../../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";

.device-group-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 -1px;
  border-radius: 3px;

  height: 48px;
  padding: 0 16px;
  cursor: pointer;

  &__title {
    flex: 1 1 auto;
    @include font-size("medium");
    @include font-weight("semi-bold");
    @include ellipsis();
    white-space: pre;
    @include side-spacing(margin, right, 8px);
  }

  &__content {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    @include side-spacing(margin, left, auto);
  }

  &__state-indicator {
    flex: 0 0 auto;
    @include side-spacing(margin, left, 16px);
    font-size: 16px;
  }
}

.collapsible-section--opened .device-group-header {
  border-radius: 3px 3px 0 0;
}
