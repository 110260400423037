@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../../styles/mixins/typography";
@import "../../../../../styles/colors";
@import "../../../../../styles/variables";

.url-check {
  &__form {
    display: flex;
    flex-direction: column;

    &__button {
      padding-bottom: 16px;
      .button {
        width: 100%;
      }
    }

    @media (min-width: $screen-sm-min) {
      flex-direction: row;

      &__input {
        width: 50%;

        @media (max-width: $screen-sm-max) {
          width: 100%;
        }

        @include side-spacing(padding, right, 16px);
      }

      &__button {
        padding-top: 24px;
        @include side-spacing(padding, left, 16px);
      }
    }
  }
}
