@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/variables";
@import "../../styles/mixins/typography";

.protection-editor {
  &__header {
    margin: 16px 0;
  }

  &__subtitle {
    @include font-size(small);

    &__level {
      @include font-weight(semi-bold);
    }
  }

  &__default-level {
    font-weight: 600;
    @include side-spacing(margin, left, 4px);
  }

  &__picker-wrapper {
    margin-top: 16px;
  }

  .categories-list {
    margin: 16px 0;

    @media (min-width: $screen-sm-min) {
      margin: 24px 0;
    }
  }
}
