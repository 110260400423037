.segmented-button {
  display: flex;
  flex: 1 0;
  min-width: 0; // fixes the ellipsis

  &:focus-within {
    outline: auto 1px Highlight;
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 1px;
  }

  &--large {
    height: 40px;
  }

  &--medium {
    height: 32px;
  }

  &--small {
    height: 26px;
  }

  &__radio-button {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + label {
      cursor: not-allowed;
    }
  }

  &__label {
    display: flex;
    flex: 1 0;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    min-width: 0; // fixes the ellipsis
    height: 40px;

    > span {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
