@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/colors";

.infections-detected {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  background-color: var(--color-danger-alt);
  color: $white;

  &__description {
    text-align: center;
  }

  .icon.fa-exclamation-circle {
    @include side-spacing(margin, right, 8px);
  }

  .button {
    @include side-spacing(margin, left, 16px);
  }

  &--mobile {
    justify-content: space-between;
    padding: 8px 16px;
    cursor: pointer;

    .infections-detected__description {
      flex: 1;
      @include side-spacing(padding, right, 12px);
    }
  }
}
