@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/colors";

$gray-border: 1px solid $silver-sand;
$blue-border: 1px solid var(--color-primary);
$blue-box-shadow: inset 1px 1px 0 0 rgba(0, 0, 0, 0.08),
  0 0 8px 0 rgba((var(--color-primary-rgb)), 0.6);

@mixin select-menu {
  &__menu-wrapper {
    top: 39px;
    z-index: -1;
    box-shadow: $blue-box-shadow;
  }

  &-menu {
    border: $blue-border;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    max-height: 160px;
    overflow-y: auto;
    outline: none;

    &__item {
      cursor: pointer;
      background-color: $white;
      line-height: 20px;
      padding: 6px 16px;
      white-space: pre;

      &--selected {
        background-color: $black-haze;
      }

      &--highlighted {
        background-color: var(--color-primary);
        color: $white;
      }

      &--disabled,
      &--disabled:hover {
        background-color: $white;
        color: $silver-sand;
      }
    }
  }
}

@mixin select-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  @include side-spacing(margin, left, 16px);
  @include side-spacing(border, left, $gray-border);

  &:after {
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    content: "\f107";
    color: var(--color-primary);
    font-size: 16px;
  }
}
