@import "./../../../styles/variables";

.login-page {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  flex: 1 1 100%;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;

    @media (min-width: $screen-sm-min) {
      margin-top: 136px;
      max-width: 360px;
    }

    &__header {
      margin-bottom: 32px;
    }
  }
}
