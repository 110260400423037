@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";

.detailed-service-panel--mobile {
  .detailed-service-panel {
    &__header {
      flex: 1;
      cursor: pointer;

      &:before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        content: "\f078";
        color: $gray-chateau;
      }

      .infection-status {
        padding-top: 8px;
      }
    }

    &__title-wrapper {
      @include side-spacing(margin, right, 20px);
      @include side-spacing(margin, left, 10px);
    }

    &__title {
      @include font-size("regular");
    }

    &__status {
      @include side-spacing(margin, left, auto);
      cursor: auto;
    }

    &__description {
      display: flex;
      flex: 1 1 100%;
      flex-flow: column nowrap;

      .infection-status {
        @include side-spacing(margin, left, 20px);
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  &.collapsible-section--opened {
    .detailed-service-panel {
      &__header {
        &:before {
          transform: rotateZ(180deg);
        }
      }

      &__title-wrapper {
        .infection-status {
          display: none;
        }
      }
    }
  }
}

.detailed-mobile-header {
  display: flex;
  justify-content: space-between;
}
