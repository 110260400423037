@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";

@mixin profile-details-image {
  mask-size: 50px;
  mask-repeat: no-repeat;
  mask-position: center;
}

.profile-details {
  @include side-spacing(margin, right, 16px);
  color: $white;
  margin-top: 16px;

  &__age-group-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      flex-flow: column nowrap;
      align-items: flex-start;
    }
  }

  &__image {
    height: 50px;
    width: 50px;
    background-color: rgba($white, 0.5);

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      margin-bottom: 8px;
    }
  }

  &__age-group {
    @include side-spacing(padding, left, 16px);

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      @include side-spacing(padding, left, 0);
    }
  }

  &__name {
    @include font-size(large);
    word-break: break-all;
    margin-bottom: 24px;
    white-space: pre-wrap;
  }

  &__action-buttons {
    margin-bottom: 24px;
    display: flex;

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      flex-flow: column nowrap;
    }

    .edit-button,
    .delete-button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        flex: none;
      }
    }

    .edit-button {
      @include side-spacing(margin, right, 8px);

      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        @include side-spacing(margin, right, 0);
        margin-bottom: 16px;
      }
    }
  }

  &__safe-search {
    margin-top: 24px;

    .safe-search__description {
      @include side-spacing(margin, right, 6px);
    }
  }

  &__checkbox {
    align-items: flex-start;
    @include side-spacing(margin, right, 8px);

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      @include side-spacing(margin, right, 0);
    }

    .checkbox-switch {
      margin-top: 2px;
    }
  }

  .safe-search-icon:hover {
    color: $spray;
  }

  &--default {
    .profile-details__image {
      mask: url("../../styles/images/default-card.svg");
      @include profile-details-image;
    }
  }

  &--adults {
    .profile-details__image {
      mask: url("../../styles/images/light-age-group-card.svg");
      @include profile-details-image;
    }
  }

  &--teens {
    .profile-details__image {
      mask: url("../../styles/images/medium-age-group-card.svg");
      @include profile-details-image;
    }
  }

  &--kids {
    .profile-details__image {
      mask: url("../../styles/images/strict-age-group-card.svg");
      @include profile-details-image;
    }
  }

  .paused-internet__time-left {
    @include float-position("text-align", left);
  }
}
