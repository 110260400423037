@import "~@sportal/cdk/styles/mixins/direction";
@import "./../../styles/variables";

.profile-url-lists {
  margin-bottom: 40px;

  &__url-check {
    margin-top: 24px;

    .url-check__form__button {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        @include side-spacing(padding, right, 16px);
      }
    }
  }

  .allow-list-override-warning + .profile-url-lists__url-check {
    margin-top: 0;
  }

  &__lists {
    width: 100%;

    .url-list__item {
      background-color: transparent;
      @include side-spacing(padding, left, 0);
    }
  }
}
