@import "../../../styles/colors";
@import "../../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/variables";

.detailed-service-panel {
  @include font-size("small");
  padding: 12px 24px;

  @media (max-width: $screen-xs-max) {
    padding: 10px 17px;
  }

  &__title {
    @include font-size("medium");
    @include side-spacing(margin, right, 8px);
  }

  &__header {
    display: flex;
    align-items: center;
    color: $white;
  }

  &__description {
    color: var(--color-accent-light);

    .infection-status .icon {
      @include side-spacing(margin, right, 4px);
    }
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-start;

    .service-status {
      margin-bottom: 7px;
    }
  }

  .infection-status {
    @include font-size("small");
    line-height: 24px;

    @media (min-width: $screen-sm-min) {
      .icon {
        @include side-spacing(margin, right, 5px);
      }
    }
  }

  &.detailed-service-panel--infected {
    .infection-status {
      color: var(--color-danger);
    }
  }

  &.detailed-service-panel--clean {
    .infection-status {
      color: var(--color-success);
    }
  }

  &.detailed-service-panel--failed {
    .infection-status {
      color: var(--color-warning);
    }
  }
}
