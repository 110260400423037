@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../spsonPanels";

.spson-panel {
  @include spson-panel-base();
  padding: 16px 24px;
  margin-bottom: 24px;
  position: relative;

  &__main {
    flex-basis: 600px;
  }

  &__header {
    margin-bottom: 8px;

    .icon {
      color: $mid-gray;
    }

    .icon + span {
      @include side-spacing(margin, left, 12px);
    }
  }

  &__actions {
    @include side-spacing(margin, left, 20px);
    display: flex;
    flex-wrap: nowrap;

    .button + .button {
      @include side-spacing(margin, left, 16px);
    }

    .manage-devices {
      text-decoration: none;
      line-height: 30px;
    }
  }

  &__close {
    position: absolute;
    top: 4px;
    @include fixed-position(right, 4px);

    .cross-button {
      color: $dusty-gray;

      &:hover {
        color: $mid-gray;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    flex-direction: column;
    align-items: baseline;

    &__header {
      margin-bottom: 24px;

      .icon {
        display: none;
      }

      .icon + span {
        @include side-spacing(margin, left, 0);
      }
    }

    &__main {
      flex-basis: auto;
    }

    &__info {
      display: none;
    }

    &__actions {
      @include side-spacing(margin, left, 0);
      width: 100%;
    }

    &__action-button {
      flex: 1 1 50%;
    }
  }
}
