@import "~@sportal/cdk/styles/mixins/direction";

.protection-status-popover {
  &__description,
  &__list-item:not(:last-child) {
    margin-bottom: 8px;
  }

  &__list {
    @include side-spacing(margin, left, 20px);
  }
}
