@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";

.segmented-buttons-group {
  &__label {
    @include font-size("small");
    margin-bottom: 4px;
  }

  &__block {
    .segmented-button {
      @media (min-width: $screen-sm-min) {
        margin-left: -1px;

        &:first-child .segmented-button__label {
          @include border-position-radius(top, left, 3px);
          @include border-position-radius(bottom, left, 3px);
        }

        &:last-child .segmented-button__label {
          @include border-position-radius(top, right, 3px);
          @include border-position-radius(bottom, right, 3px);
        }
      }

      .segmented-button__label {
        @media (max-width: $screen-xs-max) {
          border-radius: 3px;
        }
      }

      &__label {
        border: 1px solid $silver-sand;
      }

      &__radio-button {
        &:checked + .segmented-button__label {
          z-index: 1;
        }
      }
    }

    @media (max-width: $screen-xs-max) {
      flex-flow: column nowrap;

      .segmented-button + .segmented-button {
        margin-top: 15px;
      }
    }
  }
}
