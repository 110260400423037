@import "../../../styles/variables";
@import "../../../styles/colors";
@import "~@sportal/cdk/styles/mixins/direction";

.new-profile-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  color: $mine-shaft;
  background-color: $black-haze;
  border: 1px solid $mystic;
  border-radius: 3px;
  height: 100%;
  text-align: center;

  @media (min-width: $screen-md-min) {
    padding: 16px 30px;
    height: 340px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    grid-column: span 2;
    min-width: 100%;
    min-height: 130px;
  }

  @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
    padding: 16px 64px;
  }

  &__content {
    flex: 1;
    max-width: 100%;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 16px auto 0;

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      width: auto;
      max-width: 342px;
    }
  }
}
