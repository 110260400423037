@import "../../../../styles/variables";
@import "../../../../styles/colors";

.tab-section {
  margin-bottom: 24px;

  & + .tab-section {
    padding-top: 24px;
    border-top: 1px solid $silver-sand;
  }

  &__header {
    margin-bottom: 16px;

    h3 {
      margin-bottom: 8px;
    }
  }
}
