@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/colors";
@import "../../styles/mixins/grid";
@import "../../styles/mixins/sidebar";
@import "../../styles/mixins/typography";
@import "../../styles/variables";

.wizard-page {
  &__content {
    @include stretch;
  }

  &__sidebar {
    @include sidebar();
  }

  &__sidebar-container {
    @include side-spacing(padding, right, 16px);
    @include sidebar-container();
    padding-top: 12px;
  }

  &__tabs-content {
    @include tabs-content();

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      width: 100%;
    }
  }

  &__tabs-content-container {
    display: flex;
    flex-direction: column;

    @include tabs-content-container();

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      width: $screen-xs-max;
      margin: 0 auto;
      padding: 16px 24px;
    }
  }

  &__content-footer {
    display: flex;
    justify-content: flex-end;

    @media (max-width: $screen-xs-max) {
      padding: 16px;
      justify-content: normal;
      margin: auto -16px -16px;
    }

    @media (min-width: $screen-sm-min) {
      border-top: 1px solid $silver-sand;
    }
  }

  &__button-next {
    @media (max-width: $screen-xs-max) {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media (min-width: $screen-sm-min) {
      margin-top: 32px;
    }
  }
}

.multiple-wizard-page,
.single-wizard-page {
  @include stretch;
  flex-direction: column;
}

.multiple-wizard-page {
  .info-section__content {
    justify-content: space-between;
  }

  .wizard-steps-container {
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 16px 0;
  }
}

.single-wizard-page {
  .profile-info {
    display: flex;
    margin-bottom: 8px;
    @include font-weight(semi-bold);
  }
  .profile-image {
    height: 20px;
    flex: 0 0 20px;
    @include side-spacing(margin, right, 16px);

    &.default {
      mask: url("../../styles/images/default-card.svg");
      mask-size: 20px;
      mask-repeat: no-repeat;
      mask-position: center;
      background-color: $mine-shaft;
    }
  }
}
