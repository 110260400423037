@import "../../styles/colors";

.pin-group {
  &__input {
    .base-input {
      border-color: $silver-sand;
      outline: none;

      &:focus {
        box-shadow: inset 1px 1px 0 0 rgba(0, 0, 0, 0.08),
          0 0 8px 0 rgba((var(--color-primary-rgb)), 0.6);
        border-color: var(--color-primary);
      }
    }

    &-underline {
      background: repeating-linear-gradient(
          90deg,
          $silver-sand 0,
          $silver-sand 1ch,
          transparent 0,
          transparent 1.5ch
        )
        0 100%/100% 2px no-repeat;
    }

    &--has-error .base-input {
      border-color: var(--color-danger);

      &:focus {
        box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.08),
          0 0 8px 0 rgba(var(--color-danger-rgb), 0.51);
        border-color: var(--color-danger);
      }
    }
  }

  &__append {
    background: $white;
    border-color: $silver-sand;
    color: $mine-shaft;

    &:hover {
      background: $black-haze;
      color: $eastern-blue;
    }
  }
}
