@import "../../../styles/colors";

.profile-details-mobile {
  background-color: var(--color-accent-midtone);

  &__header {
    color: $white;
    padding: 16px;
    border-bottom: 1px solid $silver-sand;
  }

  &__content {
    margin: 16px;
  }
}
