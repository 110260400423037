@import "../../styles/colors";
@import "../../styles/mixins/typography";
@import "../../styles/variables";
@import "../select/select.mixins";

.combobox {
  margin-top: 4px;
  @include select-menu;

  &#{&}--opened {
    z-index: $zindex-dropdown;
    box-shadow: $blue-box-shadow;

    .combobox__search-input {
      border: $blue-border;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .combobox__toggle {
      transform: rotateX(180deg);
    }
  }

  &#{&}--disabled {
    .combobox__search {
      background-color: $black-haze;
      color: $gray-chateau;

      .combobox__toggle:after {
        color: $gray-chateau;
      }
    }
  }

  &:not(&--opened) {
    .combobox__search-input:focus {
      box-shadow: inset 1px 1px 0 0 rgba(0, 0, 0, 0.08),
        0 0 8px 0 rgba((var(--color-primary-rgb)), 0.6);
      border-color: var(--color-primary);
    }
  }

  &__label {
    @include font-size(small);
  }

  &__search {
    position: relative;
    width: 100%;
    background-color: $white;

    &-input {
      height: 40px;
      outline: none;
      width: 100%;
      padding: 5px 16px;
      @include side-spacing(padding, right, 40px);
      border: 1px solid $silver-sand;
      border-radius: 3px;
    }
  }

  &__toggle {
    position: absolute;
    top: 0;
    outline: none;
    @include fixed-position(right, 0);
    @include select-toggle;
  }
}
