@import "../../styles/colors";
@import "~@sportal/cdk/styles/mixins/direction";

$offset: -5px;
$skew: 23deg;

.popover {
  position: relative;

  &__toggle {
    cursor: pointer;
    display: inline-block;
  }

  &__header {
    margin-bottom: 8px;
    @include side-spacing(margin, right, 26px);
  }

  &__close {
    position: absolute;
    top: 0;
    @include fixed-position(right, 0);
    width: 42px;
    height: 42px;
  }

  &__content {
    background-color: $white;
    width: 280px;
    padding: 8px 16px 16px 16px;
    border: 1px solid $mystic;
    border-radius: 3px;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.23);
  }

  &__arrow,
  &__arrow:before {
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: -1;
  }

  &__arrow:before {
    content: "";
    background-color: $white;
    border-left: 1px solid $mystic;
    border-bottom: 1px solid $mystic;
  }

  &__content[data-popper-placement^="right"] {
    .popover__arrow {
      left: $offset;

      &:before {
        transform: rotate(36deg) skewY($skew);
      }
    }
  }

  &__content[data-popper-placement^="left"] {
    .popover__arrow {
      right: $offset;

      &:before {
        transform: rotate(-144deg) skewY($skew);
      }
    }
  }

  &__content[data-popper-placement^="bottom"] {
    .popover__arrow {
      top: $offset;

      &:before {
        transform: rotate(123deg) skewY($skew);
      }
    }
  }

  &__content[data-popper-placement^="top"] {
    .popover__arrow {
      bottom: $offset;

      &:before {
        transform: rotate(-55deg) skewY($skew);
      }
    }
  }
}
