@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/colors";

.switcher {
  .switch {
    background-color: $gray-chateau;

    &:hover,
    &:focus-within {
      background-color: $dusty-gray;
    }

    &__text {
      color: $white;
    }
  }

  &--checked {
    .switch {
      background-color: var(--color-accent-highlight);

      &:hover,
      &:focus-within {
        background-color: var(--color-accent-highlight-hover);
      }
    }

    input:focus + .switch {
      background-color: var(--color-accent-highlight-hover);
    }
  }

  &--disabled {
    .switch,
    .switch:hover {
      background-color: $silver-sand;
    }
  }
}
