@import "../../styles/colors";
@import "../../styles/mixins/grid";
@import "../../styles/variables";

.info-section {
  background-color: var(--color-accent-dark);

  & &__content {
    display: flex;
    align-items: center;
    min-height: 45px;

    @include adaptive($screen-xs-max, $screen-sm-max, $screen-md-max);

    @media (min-width: $screen-sm-min) {
      padding: 0 24px;
      margin: 0 auto;
    }
  }
}
