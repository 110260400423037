@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/typography";
@import "../../../../../shared/cdk/styles/mixins/direction";

.banner-inline {
  font-size: 12px;
  line-height: 20px;
  width: 100%;
  padding: 8px;
  display: flex;
  background-color: #feeac2;
  position: relative;

  @media (max-width: $screen-xs-max) {
    margin-top: 0;
  }

  .icon {
    margin-top: 1px;
  }

  &__content {
    flex: 1;
    @include side-spacing(margin, left, 8px);
  }

  &__header {
    @include font-weight("semi-bold");
  }

  &__close {
    @include side-spacing(margin, left, 16px);

    .cross-button {
      width: 16px;
      height: 16px;
    }

    .icon {
      font-size: 16px;
      color: #66676b;
    }

    @media (max-width: $screen-sm-max) {
      position: absolute;
      top: 5px;
      @include fixed-position(right, 5px);
    }
  }
}
