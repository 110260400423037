@import "../../styles/mixins/sidebar";
@import "../../styles/mixins/grid";
@import "../../styles/variables";

.profile-page {
  @include stretch;
  flex-direction: column;

  & &__content {
    @include stretch;

    @media (max-width: $screen-xs-max) {
      flex-flow: column nowrap;
    }
  }

  & &__sidebar {
    @include sidebar();
  }

  & &__sidebar-container {
    @include sidebar-container();
  }

  & &__body-content {
    @include tabs-content();
  }

  & &__body-content-container {
    @include tabs-content-container();
  }

  & &__body-content-header {
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-bottom: 1px solid $silver-sand;
  }
}
