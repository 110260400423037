@import "../../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";

.device-row {
  display: flex;
  flex-direction: row;
  padding: 14px 8px;

  &__device-name {
    flex: 1 1 auto;
    min-width: 0;
    @include ellipsis(pre);
    padding: 0 8px;
  }

  &__code {
    flex: 0 0 auto;
    width: 65px;
  }

  &__manufacturer {
    flex: 0 0 auto;
    width: 220px;
    padding: 0 8px;
    @include ellipsis();
  }

  &__network-id {
    flex: 0 0 auto;
    width: 140px;
    display: flex;
    flex-direction: column;
  }

  &__last-seen {
    flex: 0 0 auto;
    min-width: 160px;
    @include side-spacing(padding, right, 10px);
    white-space: nowrap;
  }

  &__menu {
    flex: 0 0 auto;
    width: 16px;
    cursor: pointer;
  }
}
