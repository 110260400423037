@import "~@sportal/cdk/styles/mixins/colors";
@import "~@sportal/cdk/styles/mixins/direction";
@import "./mixins/typography";
@import "./colors";

:root {
  --dark: -10%;
  --darken: -20%;

  --color-main: #{$mine-shaft};
  --color-success: #{$brand-success};

  --color-primary: #{$brand-primary};
  --color-primary-rgb: #{hexToRGB($brand-primary)};
  --color-primary-hs: #{$brand-primary-hs};
  --color-primary-lightness: #{$brand-primary-lightness};
  --color-primary-hover: hsl(
    var(--color-primary-hs),
    calc(var(--color-primary-lightness) + var(--dark))
  );
  --color-primary-pressed: hsl(
    var(--color-primary-hs),
    calc(var(--color-primary-lightness) + var(--darken))
  );

  --color-warning: #{$brand-warning};
  --color-warning-rgb: #{hexToRGB($brand-warning)};
  --color-warning-hs: #{$brand-warning-hs};
  --color-warning-lightness: #{$brand-warning-lightness};
  --color-warning-hover: hsl(
    var(--color-warning-hs),
    calc(var(--color-warning-lightness) + var(--dark))
  );

  --color-danger: #{$brand-danger};
  --color-danger-rgb: #{hexToRGB($brand-danger)};
  --color-danger-alt: #{$brand-danger-alt};
  --color-danger-alt-hs: #{$brand-danger-alt-hs};
  --color-danger-alt-lightness: #{$brand-danger-alt-lightness};
  --color-danger-alt-hover: hsl(
    var(--color-danger-alt-hs),
    calc(var(--color-danger-alt-lightness) + var(--dark))
  );

  --color-accent-dark: #{$big-stone};
  --color-accent-midtone: #{$fiord};
  --color-accent-light: #{$bermuda-gray};

  --color-accent-highlight: #{$java};
  --color-accent-highlight-hs: #{$brand-success-hs};
  --color-accent-highlight-lightness: #{$brand-success-lightness};
  --color-accent-highlight-hover: hsl(
    var(--color-accent-highlight-hs),
    calc(var(--color-accent-highlight-lightness) + var(--dark))
  );

  --color-schedule-timeline-1-rgb: #{hexToRGB($california)};
  --color-schedule-timeline-2-rgb: #{hexToRGB($scarlet)};

  --color-report-activity: #11b4e5;
  --color-report-activity-hover: var(--color-primary-hover);
  --color-report-blocks: var(--color-warning);
  --color-report-blocks-hover: var(--color-warning-hover);
  --color-report-malware: var(--color-danger-alt);
  --color-report-malware-hover: var(--color-danger-alt-hover);
}

html,
body {
  font-family: "Open Sans", Arial, sans-serif;
  color: $mine-shaft;
  @include font-size(regular);
  //Helps to avoid unexpected font-size scaling;
  // reproduces on orientation change to landscape
  text-size-adjust: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Open Sans", Arial, sans-serif;
  @include font-size(regular);
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

fieldset {
  border: none;
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

// headers
h1 {
  @include font-size(extra-large);
  @include font-weight(normal);
  @include uppercase();
}

h2 {
  @include font-size(large);
  @include font-weight(normal);
  @include uppercase();
}

h3 {
  @include font-size(large);
  @include font-weight(semi-bold);
}

h4 {
  @include font-size(medium);
  @include font-weight(semi-bold);
}

h5 {
  @include font-size(medium);
  @include font-weight(semi-bold);
  color: $big-stone;
}

h3.inverted {
  color: var(--color-accent-highlight);
}

// region text-variations
.label-txt {
  @include font-size(small);
}

.link {
  cursor: pointer;
  color: var(--color-primary);
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--color-primary-hover);
  }

  &[disabled],
  &.disabled {
    cursor: not-allowed;
    color: var(--color-primary);
    opacity: 0.6;
  }
}

.strong {
  @include font-weight("semi-bold");
}

.disabled-text {
  @include hint-txt;
}

.ellipsis-text {
  @include ellipsis();
}

.sr-only {
  position: absolute;
  @include side-spacing(margin, left, -10000px);
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
