@import "../../styles/colors";
@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/mixins/typography";

.service-status {
  @include font-size("small");
  @include font-weight("normal");
  display: flex;
  align-items: center;
  height: 20px;
  background-color: var(--color-accent-midtone);
  border-radius: 3px;
  padding: 0 8px;
  color: var(--color-accent-light);

  &::before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--color-accent-light);
    @include side-spacing(margin, right, 4px);
  }

  &--active {
    color: var(--color-accent-highlight);
    &::before {
      background-color: var(--color-accent-highlight);
    }
  }
}
