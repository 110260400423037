@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/mixins/sidebar";
@import "../../styles/mixins/grid";
@import "../../styles/colors";

.settings-page {
  @include stretch;
  flex-direction: column;

  & &__content {
    @include stretch;

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }
  }

  & &__sidebar {
    @include sidebar();
  }

  & &__sidebar-container {
    @include sidebar-container();
  }

  & &__tabs-content {
    @include tabs-content();

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      width: 100%;
    }
  }

  & &__tabs-content-container {
    @include tabs-content-container();

    @media (max-width: $screen-xs-max) {
      padding: 16px 16px 24px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      width: $screen-xs-max;
      padding: 16px 24px;
      margin: 0 auto;
    }
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .horizontal-menu {
      width: calc(#{$screen-xs-max} - 48px);
      margin: 0 auto;
    }

    .horizontal-menu__content {
      padding: 0;
    }
  }

  &__footer {
    text-align: center;
    border-top: 1px solid $silver-sand;
    padding: 25px 0;

    @media (min-width: $screen-sm-min) {
      @include float-position(text-align, right);
    }

    .button {
      @media (max-width: $screen-xs-max) {
        width: 100%;
      }
    }
  }
}
