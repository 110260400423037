@import "../../../../../../../../styles/colors";
@import "../../../../../../../../styles/mixins/typography";

.app-status {
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 11px;
  @include font-weight("semi-bold");

  &--enabled {
    background-color: rgba($brand-success, 0.5);
  }

  &--disabled {
    background-color: $mystic;
  }
}
