@import "../../../styles/colors";
@import "../../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";

.report-tooltip {
  max-width: 400px;
  padding: 12px 16px;
  font-size: 11px;
  line-height: 17px;
  background-color: rgba($black, 0.7);
  color: $white;
  pointer-events: none;

  &__counts,
  &__top .list:first-child {
    margin-bottom: 12px;
  }

  &__counts,
  &__top {
    margin-top: 8px;
    white-space: nowrap;
  }

  &__top {
    .list__header {
      font-weight: 600;
      @include font-size("small");
    }
  }

  &__top {
    .list__item {
      @include ellipsis();
    }
  }

  .count {
    display: flex;

    &__name {
      @include side-spacing(margin, right, 3px);
    }

    &__value {
      @include ellipsis();
    }
  }
}
