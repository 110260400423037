// Here we have only styles that reset button
// Link specific styles are applied by the global .link class
.button-link {
  border: none;
  outline: none;
  padding: 0;
  box-shadow: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
