@import "../../styles/mixins/grid";
@import "../../styles/variables";

.home-page {
  @include stretch;
  flex-direction: column;

  & &__content {
    @include adaptive($screen-xs-max, $screen-sm-max, $screen-md-max);
    padding: 24px 16px;

    @media (min-width: $screen-sm-min) {
      margin: 0 auto;
      padding: 24px;
    }
  }

  .household-content {
    &__description {
      margin-bottom: 8px;
    }
  }
}
