@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../styles/mixins/typography";
@import "../../../../styles/colors";
@import "../../../../styles/variables";

.wizard-protection {
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media (max-width: $screen-xs-max) {
      .button:first-child {
        margin-bottom: 16px;
      }
    }

    @media (min-width: $screen-sm-min) {
      flex-direction: row-reverse;

      .button:last-child {
        @include side-spacing(margin, right, 8px);
      }
    }
  }

  @media (min-width: $screen-md-min) {
    &__content {
      .categories-list {
        .accordion-column:first-child {
          @include side-spacing(padding, right, 26px);
        }
        .accordion-column:last-child {
          @include side-spacing(padding, left, 26px);
        }
      }
    }
  }
}

@mixin profile-card-image {
  mask-size: 20px;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: $mine-shaft;
}

.wizard-protection-header {
  margin: -24px -16px 0;

  .protection-header-content {
    padding: 16px 16px 0;

    .profile-info {
      display: flex;
      margin-bottom: 8px;
      white-space: pre;
    }

    .profile-image {
      height: 20px;
      flex: 0 0 20px;
      @include side-spacing(margin, right, 16px);

      &.default {
        mask: url("../../../../styles/images/default-card.svg");
        @include profile-card-image;
      }

      &.kids {
        mask: url("../../../../styles/images/strict-age-group-card.svg");
        @include profile-card-image;
      }

      &.adults {
        mask: url("../../../../styles/images/light-age-group-card.svg");
        @include profile-card-image;
      }

      &.teens {
        mask: url("../../../../styles/images/medium-age-group-card.svg");
        @include profile-card-image;
      }
    }
  }
}

.wizard-protection-card {
  @media (min-width: $screen-md-min) {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin-bottom: 4px;
    @include ellipsis();
    white-space: pre;
  }

  &__container {
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  &__content {
    overflow: hidden;
    @media (min-width: $screen-md-min) {
      @include side-spacing(margin, right, 16px);
    }
  }

  &__button {
    @media (max-width: $screen-sm-max) {
      margin-top: 24px;
      width: 100%;
    }
  }

  &__item {
    & + .wizard-protection-card__item {
      margin-top: 6px;
    }

    &--description {
      @include font-size("small");
    }
  }

  b {
    @include font-weight("semi-bold");
    @include font-size("regular");
  }

  .default-card__description-list {
    list-style: none;

    li {
      position: relative;
      @include side-spacing(padding, left, 25px);

      &:before {
        content: "";
        display: block;
        border-radius: 50%;
        height: 3px;
        width: 3px;
        background-color: $mine-shaft;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include fixed-position(left, 0);
      }
    }
  }
}

.wizard-page__page-title {
  padding-bottom: 16px;

  .button {
    @include side-spacing(margin, right, 16px);
  }

  @media (min-width: $screen-sm-min) {
    border-bottom: 1px solid $silver-sand;
  }
}
