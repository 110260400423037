@import "../../styles/variables";
@import "../../styles/colors";
@import "../../styles/mixins/grid";
@import "~@sportal/cdk/styles/mixins/direction";

.not-home-service-panel {
  background-color: var(--color-accent-dark);
  display: flex;
  @include side-spacing(margin, left, auto);

  @media (max-width: $screen-xs-max) {
    margin: 0;
    flex: 1;
    flex-direction: column;
  }

  .compact-service-panel + .compact-service-panel {
    border-top: 1px solid var(--color-accent-midtone);

    @media (min-width: $screen-sm-min) {
      border-top: none;
      position: relative;

      &:before {
        content: "";
        width: 1px;
        height: 31px;
        background-color: var(--color-accent-midtone);
        position: absolute;
        @include fixed-position(left, 0);
      }
    }
  }
}
