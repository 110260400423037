@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/mixins/typography";
@import "../../../styles/variables";
@import "../../../styles/colors";

.schedule-form {
  margin-bottom: 40px;

  &__content {
    width: 100%;
  }

  &__controls {
    margin-bottom: 16px;
  }

  .time-picker-group {
    @media (max-width: $screen-xxs-max) {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    @media (min-width: $screen-xs-min) {
      > div + div {
        @include side-spacing(margin, left, 40px);

        .time-picker__content {
          position: relative;

          &:before {
            content: "";
            width: 5px;
            height: 1px;
            background-color: $dusty-gray;
            position: absolute;
            @include fixed-position(left, -22.5px);
          }
        }
      }
    }

    .select {
      margin-top: 0;
    }
  }

  .checkbox-group {
    margin-top: 16px;

    &__label {
      @include font-size("small");
      margin-bottom: 5px;
    }

    &__list {
      display: flex;

      @media (max-width: $screen-xs-max) {
        flex-direction: column;
        flex-wrap: wrap;
        height: 148px;
        align-content: flex-start;
        margin-top: -16px;

        > label {
          margin-top: 16px;
          @include side-spacing(margin, right, 70px);
        }
      }

      @media (min-width: $screen-sm-min) {
        > label + label {
          @include side-spacing(margin, left, 25px);
        }
      }
    }
  }
}
