@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/mixins/typography";
@import "./select.mixins";

.select {
  margin-top: 4px;

  &#{&}--opened {
    z-index: $zindex-dropdown;
    box-shadow: $blue-box-shadow;

    .select__button {
      border: $blue-border;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &-arrow:after {
        transform: rotateX(180deg);
      }
    }
  }

  &#{&}--disabled {
    .select__button {
      background-color: $black-haze;
      color: $gray-chateau;

      &-arrow:after {
        color: $gray-chateau;
      }
    }
  }

  &__label {
    @include font-size(small);
  }

  &__button {
    height: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    @include side-spacing(padding, left, 16px);
    background-color: #fff;
    border: 1px solid $silver-sand;
    border-radius: 3px;
    color: var(--color-main);

    &-arrow {
      @include select-toggle;
    }
  }

  @include select-menu;
}
