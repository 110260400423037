@import "../variables";
@import "../colors";
@import "~@sportal/cdk/styles/mixins/direction";

@mixin container-width($content-size, $element-size) {
  width: calc((100% - #{$content-size}) / 2 + #{$element-size});
}

@mixin sidebar() {
  display: flex;
  justify-content: flex-end;
  background-color: var(--color-accent-midtone);
  @include container-width($screen-xs-max, $screen-xs-max / 4);

  @media (min-width: $screen-md-min) {
    @include container-width($screen-sm-max, $screen-sm-max / 4);
  }
  @media (min-width: $screen-lg-min) {
    @include container-width($screen-md-max, $screen-md-max / 4);
  }
}

@mixin sidebar-container() {
  @include side-spacing(padding, left, 24px);
  width: calc(#{$screen-xs-max} / 4);

  @media (min-width: $screen-md-min) {
    width: calc(#{$screen-sm-max} / 4);
  }
  @media (min-width: $screen-lg-min) {
    width: calc(#{$screen-md-max} / 4);
  }
}

@mixin tabs-content() {
  display: flex;
  width: 100%;

  @media (min-width: $screen-sm-min) {
    @include container-width(
      $screen-xs-max,
      $screen-xs-max - $screen-xs-max / 4
    );
  }
  @media (min-width: $screen-md-min) {
    @include container-width(
      $screen-sm-max,
      $screen-sm-max - $screen-sm-max / 4
    );
  }
  @media (min-width: $screen-lg-min) {
    @include container-width(
      $screen-md-max,
      $screen-md-max - $screen-md-max / 4
    );
  }
}

@mixin tabs-content-container($width-offset: 0px, $custom-padding: 16px 32px) {
  width: 100%;
  padding: 16px;

  @media (max-width: $screen-xs-max) {
    padding: 24px 16px;
  }
  @media (min-width: $screen-sm-min) {
    padding: $custom-padding;
    width: calc((#{$screen-xs-max} - #{$screen-xs-max} / 4) - #{$width-offset});
  }
  @media (min-width: $screen-md-min) {
    width: calc((#{$screen-sm-max} - #{$screen-sm-max} / 4) - #{$width-offset});
  }
  @media (min-width: $screen-lg-min) {
    width: calc((#{$screen-md-max} - #{$screen-md-max} / 4) - #{$width-offset});
  }
}
