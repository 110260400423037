@import "~@sportal/cdk/styles/mixins/direction";

.single-content {
  &__actions {
    margin-top: 16px;

    .button:first-child {
      @include side-spacing(margin, right, 16px);
    }
  }
}
