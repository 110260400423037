@import "../../styles/colors";

.checkbox.switch-base {
  &:focus-within {
    .checkbox-switch {
      outline: auto 1px Highlight;
      outline: -webkit-focus-ring-color auto 1px;
      outline-offset: 4px;
    }
  }

  &:hover,
  &:active,
  &:focus-within {
    .checkbox-switch {
      border-color: var(--color-primary-hover);

      &.checkbox-switch--error {
        border-color: var(--color-danger-alt-hover);
      }
      &.checkbox-switch--warning {
        border-color: var(--color-warning-hover);
      }
      &.checkbox-switch--disabled {
        background-color: $black-haze;
        border-color: $silver-sand;

        &:before {
          color: $silver-sand;
        }
      }
    }

    .checkbox-switch--checked {
      background-color: var(--color-primary-hover);

      &.checkbox-switch--error {
        background-color: var(--color-danger-alt-hover);
      }
      &.checkbox-switch--warning {
        background-color: var(--color-warning-hover);
      }
    }
  }
}

.checkbox-switch {
  border: 1px solid $silver-sand;
  background-color: $white;
  border-radius: 2px;
  transition: border 200ms, background-color 200ms, color 200ms;

  &--checked {
    background-color: var(--color-primary);
    border-color: var(--color-primary);

    &:before {
      font-family: "Font Awesome 5 Pro";
      font-weight: 400;
      content: "\f00c";
      color: $white;
      font-size: 12px;
    }

    &.checkbox-switch--error {
      background-color: var(--color-danger-alt);
    }
    &.checkbox-switch--warning {
      background-color: var(--color-warning);
    }
  }

  &.checkbox-switch--disabled {
    cursor: not-allowed;
    background-color: $black-haze;
    border-color: $silver-sand;

    &:before {
      color: $silver-sand;
    }
  }

  &--error {
    border-color: var(--color-danger-alt);
  }

  &--warning {
    border-color: var(--color-warning);
  }
}
