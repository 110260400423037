@import "../../styles/colors";
@import "../../styles/variables";
@import "~@sportal/cdk/styles/mixins/direction";

.wizard-steps {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  max-width: 283px;
  width: 100%;

  @media (min-width: $screen-sm-min) {
    max-width: 294px;
  }

  > .wizard-step {
    &::after {
      content: "";
      position: absolute;
      background-color: var(--color-accent-midtone);
      display: block;
      width: 100%;
      height: 2px;
      top: 14px;
      z-index: 1;
      @include fixed-position(left, 50%);
    }

    &--completed::after {
      background-color: $gray-chateau;
    }

    &:last-child::after {
      display: none;
    }
  }
}
