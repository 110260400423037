@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";

.safety-wizard {
  height: 100vh;

  &__item-top,
  &__item-bottom {
    @include side-spacing(padding, right, 15px);
    @include side-spacing(padding, left, 15px);
    height: 50vh;
  }

  &__item-top {
    background-color: var(--color-primary);
  }

  &__header {
    height: 40%;
    padding-top: 34px;
    color: $white;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      @include font-size("extra-large");
      height: 30%;
    }
  }

  &__title {
    @include font-size("large");
    @include font-weight("normal");
    text-transform: uppercase;

    @media (min-width: $screen-sm-min) {
      @include font-size("jumbo");
    }
  }

  &__subtitle {
    @include font-weight("light");
  }

  &__image-top,
  &__image-bottom {
    display: block;
    margin: 0 auto;
  }

  &__image-top {
    height: 60%;

    @media (min-width: $screen-sm-min) {
      height: 70%;
    }
  }

  &__image-bottom {
    height: 27%;

    @media (min-width: $screen-sm-min) {
      height: 32%;
    }
  }

  &__description {
    @include font-weight("light");
    text-align: center;
    margin: 15px 0;
    color: $mid-gray;

    @media (min-width: $screen-sm-min) {
      @include font-size("medium");
      margin: 25px 0;
    }
  }

  &__button {
    @include capitalize;
    display: block;
    margin: 0 auto 30px;
  }
}
