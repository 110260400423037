@import "../../../../styles/colors";
@import "../../../../styles/mixins/typography";
@import "../../../button/Button.scss";

.desktop-card {
  height: 340px;
  color: $mine-shaft;
  background-color: $black-haze;
  border: 1px solid $mystic;
  border-radius: 3px;
  text-align: center;

  &__internet-paused {
    border: 1px solid var(--color-danger);
  }

  & &__header {
    position: relative;
    color: $white;
    padding: 23px 16px 0;
    height: 116px;
    margin-bottom: 36px;
  }

  &__title {
    @include ellipsis();
    white-space: pre;
  }

  &__image {
    height: 44px;
    width: 44px;
  }

  &__image-container {
    position: absolute;
    content: "";
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 50%;
    transform: translate(-37.5px, 37.5px);
    background-color: $black-haze;
    width: 75px;
    height: 75px;
  }

  &__content {
    padding: 0 30px 33px;
  }

  &__protection-level {
    margin-bottom: 2px;
    @include capitalize;
  }

  &__button {
    width: 100%;
    margin-top: 16px;
  }

  &.default__theme {
    header {
      background-color: $mid-gray;
    }

    .desktop-card__image {
      mask: url("../../../../styles/images/default-card.svg");
      background-color: $mid-gray;
    }

    .button--default {
      @include base-button($mid-gray, $white, 10, -10);
    }
  }

  &.adults__theme {
    header {
      background-color: var(--color-primary);
    }

    .desktop-card__image {
      mask: url("../../../../styles/images/light-age-group-card.svg");
      background-color: var(--color-primary);
    }

    .button--adults {
      @include base-button($picton-blue, $white, 10, -10);
    }
  }

  &.teens__theme {
    header {
      background-color: var(--color-accent-highlight);
    }

    .desktop-card__image {
      mask: url("../../../../styles/images/medium-age-group-card.svg");
      background-color: var(--color-accent-highlight);
    }

    .button--teens {
      @include base-button($java, $white, 10, -10);
    }
  }

  &.kids__theme {
    header {
      background-color: var(--color-accent-light);
    }

    .desktop-card__image {
      mask: url("../../../../styles/images/strict-age-group-card.svg");
      background-color: var(--color-accent-light);
    }

    .button--kids {
      @include base-button($bermuda-gray, $white, 10, -10);
    }
  }
}
