@import "~@sportal/cdk/styles/mixins/direction";

.roaming-device-grid {
  padding-top: 16px;
  @include side-spacing(padding, left, 30px);
  @include side-spacing(padding, right, 86px);
  // 70px (width of action column) + 16px gap between columns
  display: grid;
  // these values come from COLUMN config in DeviceProfileTables.tsx
  grid-template-columns: minmax(200px, 1fr) minmax(50px, 200px) minmax(
      50px,
      170px
    );
  grid-template-rows: 1fr 1fr;
  grid-gap: 16px;

  &__cell {
    display: flex;

    .cell-details + .cell-details {
      @include side-spacing(margin, left, 32px);
    }
  }

  .cell-details--roaming-protection-status {
    .cell-details__value {
      display: flex;
    }
  }
}
