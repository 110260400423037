@import "../../styles/mixins/typography";

.pause-internet__modal {
  position: relative;
  top: 50%;
  padding: 8px 0;
  margin: 0 auto;
  width: 260px;
  height: auto;
  max-height: 80vh;
  border-radius: 6px;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.1);
  transform: translate(0, -50%);
  overflow-y: auto;

  li {
    padding: 16px 24px;
    @include capitalize;
  }
}

.pause-internet__modal__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
