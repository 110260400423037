@import "../../../../../../../styles/mixins/typography";
@import "../../../../../../../styles/colors";

.device-code {
  @include font-size("small");
  line-height: 22px;
  padding: 0 3px;
  border: 1px solid $silver-sand;
  border-radius: 3px;
}
