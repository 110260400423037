@import "~@sportal/cdk/styles/mixins/direction";

.header-logo {
  display: flex;
  @include side-spacing(margin, right, 32px);

  &__image {
    height: 50px;
  }
}
