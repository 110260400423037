@import "../../../styles/variables";
@import "~@sportal/cdk/styles/mixins/direction";

.devices-control-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;

  &__control:first-child {
    @include side-spacing(margin, right, auto);
  }

  &__control + &__control {
    @include side-spacing(margin, left, 16px);
  }

  @media (max-width: $screen-xs-max) {
    flex-wrap: wrap;
    height: auto;

    &__control:first-child {
      flex-basis: 100%;
      margin-bottom: 12px;
    }

    &__control:nth-child(2) {
      @include side-spacing(margin, left, 0);
      @include side-spacing(margin, right, auto);
    }
  }
}
