@import "../../styles/colors";
@import "../../styles/mixins/typography";

.time-picker {
  background-color: $white;
  border-color: $silver-sand;

  &::selection {
    background-color: $spray;
  }

  &--focused {
    border-color: var(--color-primary);
    box-shadow: inset 1px 1px 0 0 rgba(0, 0, 0, 0.08),
      0 0 8px 0 rgba((var(--color-primary-rgb)), 0.6);
  }

  &__label {
    @include font-size(small);
  }

  &__input-value {
    @include font-size(regular);
  }

  &__input-value,
  &__divider {
    background-color: $white;
    color: $mine-shaft;
  }

  &__input:focus + &__input-value,
  &__input:active + &__input-value {
    background-color: transparentize($spray, 0.5);
  }
}

.time-picker-select .select__toggle {
  background-color: $white;
}
