@import "../../../styles/variables";
@import "~@sportal/cdk/styles/mixins/direction";

.safety-content {
  display: flex;

  & &__wrapper {
    flex: 1 1 50%;
    margin-bottom: 20px;

    h2 {
      text-transform: none;
    }

    p {
      margin-top: 16px;
    }
  }

  & &__list {
    @include side-spacing(margin, left, 30px);
    margin-top: 6px;
  }

  @media (max-width: $screen-xs-max) {
    flex-direction: column;
  }

  @media (min-width: $screen-sm-min) {
    &__wrapper {
      &:first-child {
        @include side-spacing(padding, right, 15px);
      }

      &:last-child {
        @include side-spacing(padding, left, 15px);
      }
    }
  }
}
