@mixin header-left-right($class) {
  .#{$class} {
    justify-content: space-between;

    & &__content-left,
    & &__content-right {
      display: flex;
      align-items: center;
    }
  }
}

@include header-left-right(common-header);
