@import "../../styles/colors";
@import "../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";

.error-page {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 20px;
  margin-top: -10%;

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;

    &__text {
      text-transform: uppercase;
      @include font-size("extra-large");
      color: $mid-gray;
    }

    .icon {
      @include font-size("jumbo");
      width: auto;
      height: auto;
      @include side-spacing(margin, right, 8px);
      color: $dusty-gray;
    }
  }

  &__description + &__description {
    margin-top: 8px;
  }

  &__custom-button {
    color: var(--color-primary);
    border: none;
    background-color: transparent;
    padding: 0;
    outline: none;
    cursor: pointer;
  }
}
