@import "../styles/mixins/direction";

.carousel {
  width: 100%;

  &-slider {
    display: flex;
    align-items: center;
    position: relative;

    &__arrow {
      position: absolute;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75px;
      height: 75px;
      border-radius: 50%;
      border: none;
      outline: none;
      transition: all 0.3s ease;
      transform: translateY(-50%);
      user-select: none;
      cursor: pointer;
      opacity: 0.3;

      &:focus,
      &:hover {
        opacity: 0.8;
      }

      &--prev {
        @include fixed-position(left, -100px);
      }

      &--next {
        @include fixed-position(right, -100px);
      }

      .rtl & {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &-slides {
    width: 100%;
    overflow: hidden;

    &__container {
      display: flex;
      width: 100%;
      transition: margin 0.5s ease;
    }

    &__item {
      overflow: hidden;
    }
  }

  &-indicators {
    list-style: none;
    display: block;
    text-align: center;
    user-select: none;

    &__item {
      display: inline-block;
      margin: 0 4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all 0.1s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
