@import "../../../../../styles/mixins/typography";
@import "../../../../../styles/colors";

.empty-tbody-mobile {
  display: flex;
  justify-content: center;
  align-items: center;

  &__cell {
    @include italic();
    text-align: center;
    padding: 24px;
    color: $dusty-gray;
  }
}
