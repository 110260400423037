@import "../../../styles/mixins/typography";

.device-card-desktop {
  margin-bottom: 8px; // TODO: move out to list styles
  border-radius: 3px;

  &--dark {
    color: $white;
    background-color: var(--color-primary);
  }

  &--light {
    background-color: $black-haze;
  }
}
