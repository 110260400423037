@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/variables";

.profile-section__footer-form-controls {
  display: flex;
  align-items: center;

  @media (min-width: $screen-sm-min) {
    @include side-spacing(padding, right, 16px);
  }
}

.profile-section__form-controls-buttons .button {
  min-width: 85px;

  + .button {
    @include side-spacing(margin, left, 8px);
  }
}
