@import "../../../../styles/mixins/typography";
@import "../../../../styles/colors";

.empty-tbody {
  &__cell {
    @include italic();
    text-align: center;
    padding: 24px;
    color: $dusty-gray;
  }
}
