@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/mixins/typography";
@import "../../styles/colors";
@import "../../styles/variables";

.deeplink-modal {
  .btn {
    width: 100%;
  }

  &__status {
    color: $mid-gray;
    @include font-size(small);
  }

  &__text {
    margin-top: 16px;
  }

  &__status &__icon {
    @include font-size(medium);
    @include side-spacing(margin, right, 8px);
  }

  &__copy-with-instructions {
    margin-top: 16px;
    padding: 16px 8px;
    background-color: $black-haze;
    text-align: center;
    font-size: 9px;
    line-height: 17px;

    .button-link .icon-copy {
      @include side-spacing(margin, right, 10px);
    }

    &__text {
      margin-top: 16px;
      color: $mid-gray;
    }

    b {
      display: block;
      font-weight: 600;
    }
  }

  &--success {
    .deeplink-modal__status .deeplink-modal__icon {
      color: var(--color-success);
    }

    .modal__body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .modal__footer {
      flex-direction: column;
    }
  }

  &--error {
    .deeplink-modal__status .deeplink-modal__icon {
      color: var(--color-danger);
    }

    .modal__footer {
      justify-content: center;

      .button {
        width: 100%;
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    .status-content {
      margin-bottom: 24px;
    }

    &__status,
    &__text {
      margin-top: 24px;
      text-align: center;
    }
    &__copy-with-instructions {
      margin-top: 16px;
    }

    .modal__footer,
    .modal__body {
      margin: 0 16px;
    }

    &--error .modal__footer {
      margin-top: 24px;
    }
  }

  @media (max-width: $screen-xs-max) {
    &__copy-with-instructions__text {
      @include float-position(text-align, left);
    }

    .modal__footer {
      border: none;
      box-shadow: none;
      padding-top: 0;
    }
  }
}
