@import "../../../../../../../styles/colors";

.toggle-actions-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    background-color: rgba($silver-sand, 0.5);
  }

  &[aria-expanded="true"] {
    background-color: $silver-sand;
  }
}
