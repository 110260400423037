@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../styles/mixins/typography";
@import "../../../../styles/colors";

.thead {
  display: block;
  padding: 0 16px;
  background-color: rgba($silver-sand, 0.5);
  border-bottom: 1px solid rgba($silver-sand, 0.5);

  .th {
    line-height: 24px;
    margin: 0 -8px;
    @include font-weight("semi-bold");

    &.th--actions {
      text-align: center;
    }

    &--name,
    &--manufacturer,
    &--lastSeen {
      &:hover {
        background-color: $silver-sand;

        .sort-button__icon--unsorted {
          color: $gray-chateau;
        }
      }
    }

    &--disabled {
      pointer-events: none;
    }
  }

  .sort-button:disabled {
    color: $mine-shaft;
  }
}
