.base-input {
  &.base-input--hidden {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}

.base-input::-ms-reveal {
  display: none;
}
