.requests-section {
  margin-bottom: 16px;
  border: 1px solid var(--color-accent-dark);
  border-radius: 3px;

  &__header {
    background-color: var(--color-accent-dark);
    color: #fff;
  }
}
