@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/colors";
@import "../../styles/variables";

@mixin spson-panel-base {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $black-haze;
  border-radius: 3px;
  border: 1px solid $silver-sand;
}
