@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/mixins/typography";
@import "../../styles/colors";

.categories-item {
  &__title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 4px;
  }

  &__name {
    display: flex;

    .icon {
      @include side-spacing(margin, right, 4px);
      font-size: 14px;
    }
  }

  &__protection {
    margin-top: -4px;
    @include side-spacing(margin, left, 24px);
    color: $mid-gray;
    @include font-size(small);
    @include italic;

    &-name {
      @include capitalize;
      @include font-weight(semi-bold);
    }
  }

  &__description {
    @include font-size(small);
    @include side-spacing(margin, left, 24px);
    padding-top: 4px;
  }
}
