@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../../../styles/mixins/typography";
@import "../../../../../styles/colors";

.tbody-mobile {
  display: block;
  width: 100%;

  .tr {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 16px;
    border-bottom: 1px solid rgba($silver-sand, 0.5);

    &:nth-child(even) {
      background-color: $black-haze;
    }
  }

  .td {
    &--name {
      margin: 0 16px 0 8px;
      flex-basis: calc(
        100% - 68px
      ); // 68px - first col width + this col side margin + 3rd col width
    }

    &:nth-child(n + 4):not(.td--empty):not(.td--details) {
      margin-top: 4px;
      flex-basis: 100%;
    }

    &--details {
      margin-top: 12px;
    }

    &:not(.td--actions) {
      @include ellipsis();
    }
  }
}
