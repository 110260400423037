@import "../../../styles/variables";
@import "../../../../../../shared/cdk/styles/mixins/direction";

.roaming-limit-reached-warning {
  margin: 8px 0;
  align-items: flex-start;

  @media (max-width: $screen-xs-max) {
    margin: 0;
  }

  @media (min-width: $screen-sm-min) {
    margin-bottom: 16px;
  }

  &__content {
    @include side-spacing(margin, right, 0);
  }
}
