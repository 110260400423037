@import "../../styles/variables";

.profile-devices {
  margin-bottom: 16px;

  &__section-header {
    margin-top: 48px;
  }

  &__description {
    margin-top: 8px;
    margin-bottom: 16px;
  }
}
