@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/mixins/grid";
@import "../../../styles/variables";

.notifications-list--temporary {
  position: fixed;
  z-index: $zindex-notification;
  top: 78px; // According to what Diana's said the fixed notifications are going to be placed at the same position always, so as ex. desktop => (height of the header + 8px)
  left: 50%;
  transform: translateX(-50%);
  @include adaptive(
    $screen-xs-max - 175px,
    $screen-sm-max - 175px,
    $screen-md-max - 175px
  );

  @media (max-width: $screen-xs-max) {
    width: calc(100% - 32px);
  }

  .notification {
    + .notification {
      margin-top: 12px;
    }

    &--temporary {
      border-radius: 6px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    }

    &__body {
      padding: 15px 48px;

      &:before {
        @include fixed-position(left, 13px);
      }
    }

    &__close-button {
      @include fixed-position(right, 0);
    }
  }
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms linear;
}
