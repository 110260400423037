@import "~@sportal/cdk/styles/mixins/direction";
@import "../../styles/variables";

.url-lists {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 50%));
  grid-gap: 32px;
  margin-top: 16px;

  @media (max-width: $screen-xs-max) {
    grid-gap: 64px;
  }

  @media (max-width: $screen-sm-max) {
    grid-template-columns: 100%;

    .url-list:first-child {
      margin-top: 8px;
    }
  }
}
