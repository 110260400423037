@import "../../../../styles/mixins/typography";

.learn-more-dialog {
  &__text-block {
    @include font-size(regular);

    &:not(:last-child) {
      padding-bottom: 8px;
    }
  }
}
