@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/typography";
@import "~@sportal/cdk/styles/mixins/direction";

.footer {
  @include font-size("small");
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  height: 50px;
  background-color: var(--color-accent-dark);
  color: $white;

  @media (max-width: $screen-xs-max) {
    flex-flow: column nowrap;
  }

  &__company-link,
  &__terms-and-conditions {
    @include side-spacing(padding, left, 3px);
    @include side-spacing(padding, right, 3px);
    text-decoration: none;
  }

  &__terms-and-conditions {
    @media (max-width: $screen-xs-max) {
      @include side-spacing(padding, left, 0);
    }
  }
}
