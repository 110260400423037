@import "~@sportal/cdk/styles/mixins/direction";
@import "../../../styles/mixins/grid";
@import "../../../styles/variables";
@import "../../../styles/colors";

.notifications-list--permanent {
  background-color: var(--color-accent-dark);

  .notification__body {
    @include adaptive($screen-xs-max, $screen-sm-max, $screen-md-max);
    margin: 0 auto;
    padding: 15px 59px;

    &:before {
      @include fixed-position(left, 24px);
    }
  }

  .notification {
    + .notification {
      margin-top: 1px;
    }

    &__close-button {
      @include fixed-position(right, 8px);
    }
  }
}
