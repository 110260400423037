@import "~@sportal/cdk/styles/mixins/direction";

.cell-details-mobile.td.td--details {
  &.td--roaming-app-status {
    width: 75px;
  }

  &.td--roaming-app-version {
    @include side-spacing(margin, left, 32px);
    width: calc(100% - 75px - 32px);
  }

  &.td--roaming-protection-status {
    .cell-details-mobile__value {
      display: flex;
    }
  }
}
