.layout {
  display: flex;
  flex-direction: column; // we want the container to behave like block element
  justify-content: space-between; // if .layout_container is not provided we will get footer at the bottom of the page and header at the top anyway
  min-height: 100vh;

  & &__container {
    display: flex;
    flex-direction: column; // we want the container to behave like block element but maintain 100% height
    flex: 1 1 100%; // so the container gets 100% width and 100% height whats left from the page
  }
}
