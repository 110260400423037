//== Media queries breakpoints
// Usage: Variable suffix must match media feature prefix
//        Mobile version:
//        @media (max-width: $screen-xs-max)
//        Not mobile:
//        @media (min-width: $screen-sm-min)
//        Desktop:
//        @media (min-width: $screen-md-min)
//        Lower than Desktop:
//        @media (max-width: $screen-sm-max)

// Extra small screen / phone
// 480 - 767
$_screen-xs: 480px;
$screen-xs-min: $_screen-xs;

// Small screen / tablet
// 768 - 991
$_screen-sm: 768px;
$screen-sm-min: $_screen-sm;

// Medium screen / desktop
// 992 - 1199
$_screen-md: 992px;
$screen-md-min: $_screen-md;

// Large screen / wide desktop
// 1200 - Infinity
$_screen-lg: 1200px;
$screen-lg-min: $_screen-lg;

// So media queries don't overlap when required, provide a maximum
$screen-xxs-max: ($_screen-xs - 1);
$screen-xs-max: ($_screen-sm - 1);
$screen-sm-max: ($_screen-md - 1);
$screen-md-max: ($_screen-lg - 1);

//== Z-index master list
$base-layout: 0;
$zindex-spinner-local: $base-layout + 90;
$zindex-dropdown: $base-layout + 100;
$zindex-popover: $base-layout + 110;
$zindex-form-element: $base-layout + 120;
$zindex-menu: $base-layout + 200;
$zindex-notification: $base-layout + 210;

$navbar-layout: 1000;
$zindex-mobile-menu-backdrop: $navbar-layout;
$zindex-profile-sticky: $navbar-layout + 30;
$zindex-mobile-menu: $navbar-layout + 50;
$zindex-spinner: $navbar-layout + 100;

$modal-layout: 1500;
$zindex-modal-background: $modal-layout;
$zindex-modal: $modal-layout + 50;

$zindex-tooltip: 2000;
$zindex-loader: 5000;
